import { PolicyNumberPrefix, YouCantMakeAClaimContent } from "raci-react-library";
import { useEffect } from "react";
import useFlowState from "../../shared/components/FlowStateProvider/useFlowState";
import { ErrorRoute } from "../../shared/routing/routes.config";

export const YouCantMakeAClaim = () => {
  const [, setFlowCompleted] = useFlowState();

  useEffect(() => {
    setFlowCompleted(ErrorRoute.YouCantMakeAClaim);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <YouCantMakeAClaimContent policyNumberPrefix={PolicyNumberPrefix.Motor} />;
};

export default YouCantMakeAClaim;
