import AboutTheAccident from "../../views/AboutTheAccident";
import AboutYourCar from "../../views/AboutYourCar";
import BeforeYouStart from "../../views/BeforeYouStart";
import Confirmation from "../../views/Confirmation";
import ContactDetails from "../../views/ContactDetails";
import DriverHistory from "../../views/DriverHistory";
import DriverOfYourCar from "../../views/DriverOfYourCar";
import MoreAboutTheAccident from "../../views/MoreAboutTheAccident";
import MoreThirdPartyDetails from "../../views/MoreThirdPartyDetails";
import RepairerOptions from "../../views/RepairerOptions";
import ReviewYourClaim from "../../views/ReviewYourClaim";
import SessionTimeout from "../../views/SessionTimeout";
import StartYourClaim from "../../views/StartYourClaim";
import SystemUnavailable from "../../views/SystemUnavailable";
import ThirdPartyDetails from "../../views/ThirdPartyDetails";
import WhereAndHow from "../../views/WhereAndHow";
import WheresYourCar from "../../views/WheresYourCar";
import WitnessDetails from "../../views/WitnessDetails";
import YouCantMakeAClaim from "../../views/YouCantMakeAClaim";
import YourPolicy from "../../views/YourPolicy";

// <------------------>
//  Routes
// <------------------>
export enum PreFormRoute {
  BeforeYouStart = "before-you-start",
}

export enum FormRoute {
  ContactDetails = "contact-details",
  YourPolicy = "your-policy",
  StartYourClaim = "start-your-claim",
  AboutTheAccident = "about-the-accident",
  MoreAboutTheAccident = "more-about-the-accident",
  WhereAndHow = "where-and-how",
  DriverOfYourCar = "driver-of-your-car",
  DriverHistory = "driver-history",
  ThirdPartyDetails = "third-party-details",
  MoreThirdPartyDetails = "more-third-party-details",
  WitnessDetails = "witnesses",
  AboutYourCar = "about-your-car",
  WheresYourCar = "wheres-your-car",
  ReviewYourClaim = "review-your-claim",
  RepairerOptions = "repairer-options",
  Confirmation = "confirmation",
}

export enum ErrorRoute {
  YouCantMakeAClaim = "dont-have-a-policy",
  SessionTimeout = "408",
  SystemUnavailable = "error",
}

export enum UtilityRoute {
  Redirect = "redirect",
}

// <------------------>
//  Page Names
// <------------------>
export enum PreFormPageName {
  BeforeYouStart = "Before you start",
}

export enum FormPageName {
  ContactDetails = "Contact details",
  YourPolicy = "Your policy",
  StartYourClaim = "Start your claim",
  AboutTheAccident = "About the accident",
  MoreAboutTheAccident = "More about the accident",
  WhereAndHow = "Where and how",
  DriverOfYourCar = "Driver of your car",
  DriverHistory = "Driver history",
  PropertyOrPetOwner = "Property or pet owner",
  OtherVehicleDriver = "Other vehicle driver",
  MoreOnTheOwner = "More on the owner",
  OtherVehicleOwner = "Other vehicle owner",
  WitnessDetails = "Witnesses",
  AboutYourCar = "About your car",
  WheresYourCar = "Where's your car",
  ReviewYourClaim = "Review your claim",
  RepairerOptions = "Repairer options",
  Confirmation = "Confirmation",
}

export enum ErrorPageName {
  YouCantMakeAClaim = "Don't have a policy",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
}

// <------------------>
//  Page Headings
// <------------------>
export enum PreFormPageHeading {
  BeforeYouStart = "Car accident claim",
}

export enum ErrorPageHeading {
  YouCantMakeAClaim = "Uh oh!",
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
}

export type AppRoute = PreFormRoute | FormRoute | ErrorRoute | UtilityRoute;

export const getRouteUrl = (route: AppRoute) => `${process.env.PUBLIC_URL}/${route}`;

export const ERROR_SYSTEM_UNAVAILABLE_PAGE_URL = getRouteUrl(ErrorRoute.SystemUnavailable);
export const ERROR_SESSION_TIMEOUT_PAGE_URL = getRouteUrl(ErrorRoute.SessionTimeout);
export const PRE_FORM_BEFORE_YOU_START_URL = getRouteUrl(PreFormRoute.BeforeYouStart);
export const FORM_REVIEW_YOUR_CLAIM_URL = getRouteUrl(FormRoute.ReviewYourClaim);
export const FORM_REPAIRER_OPTIONS_URL = getRouteUrl(FormRoute.RepairerOptions);
export const UTILITY_REDIRECT_PAGE_URL = getRouteUrl(UtilityRoute.Redirect);

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const preFormRouteInfo: Record<PreFormRoute, RouteInformation> = {
  [PreFormRoute.BeforeYouStart]: {
    key: PreFormRoute.BeforeYouStart,
    path: getRouteUrl(PreFormRoute.BeforeYouStart),
    name: PreFormPageName.BeforeYouStart,
    heading: PreFormPageHeading.BeforeYouStart,
    element: <BeforeYouStart />,
  },
};

export const formRouteInfo: Record<FormRoute, RouteInformation> = {
  [FormRoute.ContactDetails]: {
    key: FormRoute.ContactDetails,
    path: getRouteUrl(FormRoute.ContactDetails),
    name: FormPageName.ContactDetails,
    element: <ContactDetails />,
  },
  [FormRoute.YourPolicy]: {
    key: FormRoute.YourPolicy,
    path: getRouteUrl(FormRoute.YourPolicy),
    name: FormPageName.YourPolicy,
    element: <YourPolicy />,
  },
  [FormRoute.StartYourClaim]: {
    key: FormRoute.StartYourClaim,
    path: getRouteUrl(FormRoute.StartYourClaim),
    name: FormPageName.StartYourClaim,
    element: <StartYourClaim />,
  },
  [FormRoute.AboutTheAccident]: {
    key: FormRoute.AboutTheAccident,
    path: getRouteUrl(FormRoute.AboutTheAccident),
    name: FormPageName.AboutTheAccident,
    element: <AboutTheAccident />,
  },
  [FormRoute.MoreAboutTheAccident]: {
    key: FormRoute.MoreAboutTheAccident,
    path: getRouteUrl(FormRoute.MoreAboutTheAccident),
    name: FormPageName.MoreAboutTheAccident,
    element: <MoreAboutTheAccident />,
  },
  [FormRoute.WhereAndHow]: {
    key: FormRoute.WhereAndHow,
    path: getRouteUrl(FormRoute.WhereAndHow),
    name: FormPageName.WhereAndHow,
    element: <WhereAndHow />,
  },
  [FormRoute.DriverOfYourCar]: {
    key: FormRoute.DriverOfYourCar,
    path: getRouteUrl(FormRoute.DriverOfYourCar),
    name: FormPageName.DriverOfYourCar,
    element: <DriverOfYourCar />,
  },
  [FormRoute.DriverHistory]: {
    key: FormRoute.DriverHistory,
    path: getRouteUrl(FormRoute.DriverHistory),
    name: FormPageName.DriverHistory,
    element: <DriverHistory />,
  },
  [FormRoute.ThirdPartyDetails]: {
    key: FormRoute.ThirdPartyDetails,
    path: getRouteUrl(FormRoute.ThirdPartyDetails),
    name: FormPageName.OtherVehicleDriver,
    element: <ThirdPartyDetails />,
  },
  [FormRoute.MoreThirdPartyDetails]: {
    key: FormRoute.MoreThirdPartyDetails,
    path: getRouteUrl(FormRoute.MoreThirdPartyDetails),
    name: FormPageName.OtherVehicleOwner,
    element: <MoreThirdPartyDetails />,
  },
  [FormRoute.WitnessDetails]: {
    key: FormRoute.WitnessDetails,
    path: getRouteUrl(FormRoute.WitnessDetails),
    name: FormPageName.WitnessDetails,
    element: <WitnessDetails />,
  },
  [FormRoute.AboutYourCar]: {
    key: FormRoute.AboutYourCar,
    path: getRouteUrl(FormRoute.AboutYourCar),
    name: FormPageName.AboutYourCar,
    element: <AboutYourCar />,
  },
  [FormRoute.WheresYourCar]: {
    key: FormRoute.WheresYourCar,
    path: getRouteUrl(FormRoute.WheresYourCar),
    name: FormPageName.WheresYourCar,
    element: <WheresYourCar />,
  },
  [FormRoute.ReviewYourClaim]: {
    key: FormRoute.ReviewYourClaim,
    path: getRouteUrl(FormRoute.ReviewYourClaim),
    name: FormPageName.ReviewYourClaim,
    element: <ReviewYourClaim />,
  },
  [FormRoute.RepairerOptions]: {
    key: FormRoute.RepairerOptions,
    path: getRouteUrl(FormRoute.RepairerOptions),
    name: FormPageName.RepairerOptions,
    element: <RepairerOptions />,
  },
  [FormRoute.Confirmation]: {
    key: FormRoute.Confirmation,
    path: getRouteUrl(FormRoute.Confirmation),
    name: FormPageName.Confirmation,
    element: <Confirmation />,
  },
};

export const errorRouteInfo: Record<ErrorRoute, RouteInformation> = {
  [ErrorRoute.YouCantMakeAClaim]: {
    key: ErrorRoute.YouCantMakeAClaim,
    path: getRouteUrl(ErrorRoute.YouCantMakeAClaim),
    name: ErrorPageName.YouCantMakeAClaim,
    element: <YouCantMakeAClaim />,
    heading: ErrorPageHeading.YouCantMakeAClaim,
  },
  [ErrorRoute.SessionTimeout]: {
    key: ErrorRoute.SessionTimeout,
    path: getRouteUrl(ErrorRoute.SessionTimeout),
    name: ErrorPageName.SessionTimeout,
    element: <SessionTimeout />,
    heading: ErrorPageHeading.SessionTimeout,
  },
  [ErrorRoute.SystemUnavailable]: {
    key: ErrorRoute.SystemUnavailable,
    path: getRouteUrl(ErrorRoute.SystemUnavailable),
    name: ErrorPageName.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: ErrorPageHeading.SystemUnavailable,
  },
};

export const ErrorRoutePaths = Object.values(errorRouteInfo).map((route) => route.path);
export const PreFormRoutePaths = Object.values(preFormRouteInfo).map((route) => route.path);

export const preFormRoutes: RouteInformation[] = Object.values(preFormRouteInfo);
export const formRoutes: RouteInformation[] = Object.values(formRouteInfo);
export const errorRoutes: RouteInformation[] = Object.values(errorRouteInfo);
export const allRoutes = [...preFormRoutes, ...formRoutes, ...errorRoutes];
export const standardPageRoutes = [...preFormRoutes, ...errorRoutes];
