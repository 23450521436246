import { event, gtm } from "@racwa/analytics";
import { useGetSessionState, useSessionState } from "raci-react-library";
import { useForm } from "react-hook-form";
import useFlowState from "../../../../shared/components/FlowStateProvider/useFlowState";
import useIsClaimCreated from "../../../../shared/hooks/useIsClaimCreated";
import { FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { StartYourClaimState } from "../../../StartYourClaim/types";
import { OtherVehiclesInvolved } from "../../constants";
import { AboutTheAccidentFormProps, AboutTheAccidentFormValues, AboutTheAccidentState } from "../../types";

export const useAboutTheAccident = (): AboutTheAccidentFormProps => {
  const navigate = useNavigateToRoute();
  const claimCreated = useIsClaimCreated();
  const [{ isFlowCompleted }, setFlowCompleted] = useFlowState();
  const { hasComprehensiveCover } = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const [aboutTheAccidentState, setAboutTheAccidentState] = useSessionState<AboutTheAccidentState>({
    skipPageTrackingRecalculation: claimCreated,
  });

  const form = useForm<AboutTheAccidentFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: aboutTheAccidentState,
  });

  const onSubmit: AboutTheAccidentFormProps["onSubmit"] = async (newValues) => {
    setAboutTheAccidentState({ ...newValues, isCompleted: true });

    if (
      isValidNumberOfVehiclesInvolved({
        otherVehiclesInvolved: newValues.otherVehiclesInvolved,
        hasComprehensiveCover: !!hasComprehensiveCover,
      })
    ) {
      triggerGA(newValues);
      navigate(FormRoute.MoreAboutTheAccident);
    } else {
      gtm(event("Sorry, you can't claim online"));
      setFlowCompleted(FormRoute.AboutTheAccident);
    }
  };

  return {
    form,
    hasComprehensiveCover: !!hasComprehensiveCover,
    claimCreated,
    youCantClaimOnline: isFlowCompleted,
    onSubmit,
  };
};

export const isValidNumberOfVehiclesInvolved = ({
  otherVehiclesInvolved,
  hasComprehensiveCover,
}: {
  otherVehiclesInvolved: OtherVehiclesInvolved;
  hasComprehensiveCover: boolean;
}) =>
  hasComprehensiveCover
    ? otherVehiclesInvolved === OtherVehiclesInvolved.One ||
      otherVehiclesInvolved === OtherVehiclesInvolved.None ||
      otherVehiclesInvolved === OtherVehiclesInvolved.ImNotSure
    : otherVehiclesInvolved === OtherVehiclesInvolved.One;

const triggerGA = (newValues: AboutTheAccidentFormValues) => {
  if (
    newValues.otherVehiclesInvolved === OtherVehiclesInvolved.None ||
    newValues.otherVehiclesInvolved === OtherVehiclesInvolved.ImNotSure
  ) {
    gtm(event("Claim type - Single vehicle"));
  } else {
    gtm(event("Claim type - Multi vehicle"));
  }
};

export default useAboutTheAccident;
