import { Typography } from "@mui/material";
import { ClaimDetailsLockedCard, NotificationCard } from "raci-react-library";

interface ClaimDetailsCardProps {
  claimCreated?: boolean;
}

export const ClaimDetailsCard = ({ claimCreated = false }: ClaimDetailsCardProps) =>
  claimCreated ? <ClaimDetailsLockedCard /> : <CheckYourClaimDetailsCard />;

const CheckYourClaimDetailsCard = () => (
  <NotificationCard
    severity="info"
    message={{
      title: "Please check this is correct",
      content: <Typography>You can't change this online once you progress.</Typography>,
    }}
  />
);

export default ClaimDetailsCard;
