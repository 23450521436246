import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const sidebarTitle = "Car accident claim";
export const sidebarContent = (
  <SidebarContent
    pdsUrl={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
    feedbackUrl={process.env.REACT_APP_RAC_FEEDBACK_URL || EMPTY_URL}
  />
);

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const navigate = useNavigate();
  const commonPageProps = usePage();
  const { steps, activeStepIndex, navigateToPreviousStep, canNavigateToPreviousStep } = useRoutes();

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: navigateToPreviousStep,
  };

  const onStepClick = (_: string, stepIndex: number) => {
    if (canNavigateToPreviousStep && activeStepIndex !== stepIndex) {
      navigate(steps[`${stepIndex}`].path);
    }
  };

  return {
    sidebarTitle,
    sidebarContent,
    steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
