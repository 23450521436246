import { RadioButtonGroupItem } from "raci-react-library";
import { FormQuestions } from "../../shared/types";
import { DriverOfYourCarFormValues } from "./types";

export const driverOfYourCarPageTitle = "Driver of your car";

export const DriverOfYourCarQuestions = {
  isDriver: {
    id: "is-driver",
    name: "isDriver",
    label: "Were you the driver?",
  },
  policyDriver: {
    id: "policy-driver",
    name: "policyDriver",
    label: "Was the driver...",
  },
  otherDriverFirstName: {
    id: "other-driver-first-name",
    name: "otherDriverFirstName",
    label: "First name",
  },
  otherDriverMiddleName: {
    id: "other-driver-middle-name",
    name: "otherDriverMiddleName",
    label: "Middle name",
  },
  otherDriverLastName: {
    id: "other-driver-last-name",
    name: "otherDriverLastName",
    label: "Last name",
  },
  otherDriverDateOfBirth: {
    id: "other-driver-date-of-birth",
    name: "otherDriverDateOfBirth",
    label: "Date of birth",
  },
  otherDriverContactNumber: {
    id: "other-driver-contact-number",
    name: "otherDriverContactNumber",
    label: "Contact number",
  },
  otherDriverAddress: {
    id: "other-driver-address",
    name: "otherDriverAddress",
    label: "Address",
  },
} as const satisfies FormQuestions<DriverOfYourCarFormValues>;

export const OtherDriverItem = {
  id: "-1",
  value: "-1",
  label: "Someone else",
} as const satisfies RadioButtonGroupItem;
