import { Button, Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import {
  AddressInput,
  ContactNumberInput,
  DateOfBirthInput,
  FirstNameInput,
  LastNameInput,
  NameInput,
  OptionalText,
  RadioButtonGroup,
  YesNoButtonGroup,
  pleaseEnterAValidMessage,
} from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { StyledDivider } from "../../shared/components/Styled";
import { DriverOfYourCarQuestions, OtherDriverItem, driverOfYourCarPageTitle } from "./constants";
import { DriverOfYourCarFormProps } from "./types";

const {
  isDriver: isDriverQuestion,
  policyDriver: policyDriverQuestion,
  otherDriverFirstName: otherDriverFirstNameQuestion,
  otherDriverMiddleName: otherDriverMiddleNameQuestion,
  otherDriverLastName: otherDriverLastNameQuestion,
  otherDriverContactNumber: otherDriverContactNumberQuestion,
  otherDriverDateOfBirth: otherDriverDateOfBirthQuestion,
  otherDriverAddress: otherDriverAddressQuestion,
} = DriverOfYourCarQuestions;

const nextButtonId = "submit-button";

export const DriverOfYourCarForm = ({
  form,
  driverOptions,
  addressInputProps: { addressResults, onAddressSelection, onAddressInputChange, isLoading: addressInputIsLoading },
  onSubmit,
}: DriverOfYourCarFormProps) => {
  const {
    control,
    handleSubmit,
    unregister,
    formState: { isSubmitting },
  } = form;
  const isDriver = useWatch({ control, name: "isDriver" });
  const policyDriver = useWatch({ control, name: "policyDriver" });
  const showDriversDetails = isDriver === false; // isDriver is tri-state since it can be undefined when not selected
  const showPolicyDrivers = driverOptions.length > 1;
  const showOtherDriver = driverOptions.length === 0 || policyDriver === OtherDriverItem.value;

  const unregisterFillInTheDriversDetails = () => {
    unregister("otherDriverFirstName");
    unregister("otherDriverMiddleName");
    unregister("otherDriverLastName");
    unregister("otherDriverDateOfBirth");
    unregister("otherDriverContactNumber");
    unregister("otherDriverAddress");
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle title={driverOfYourCarPageTitle} id="driver-of-your-car-header" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <YesNoButtonGroup
              id={isDriverQuestion.id}
              name={isDriverQuestion.name}
              label={isDriverQuestion.label}
              onChange={(_, value) => {
                if (value) {
                  unregister("policyDriver");
                  unregisterFillInTheDriversDetails();
                }
              }}
            />
          </Grid>
          {showDriversDetails && (
            <Grid item xs={12}>
              {showPolicyDrivers && (
                <Grid item xs={12}>
                  <RadioButtonGroup
                    id={policyDriverQuestion.id}
                    name={policyDriverQuestion.name}
                    label={policyDriverQuestion.label}
                    items={driverOptions}
                    fullwidth
                    onChange={(_, value: string) => {
                      value !== OtherDriverItem.id && unregisterFillInTheDriversDetails();
                      gtm(fieldTouched("Was the driver"));
                      gtm(event(`Was the driver - ${value === OtherDriverItem.id ? "Someone else" : "Listed driver"}`));
                    }}
                  />
                </Grid>
              )}
              {showOtherDriver && (
                <Grid item xs={12}>
                  <StyledDivider />
                  <Typography variant="h3">Fill in the driver's details</Typography>
                  <Grid item xs={12}>
                    <FirstNameInput
                      formControllerName={otherDriverFirstNameQuestion.name}
                      label={otherDriverFirstNameQuestion.label}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NameInput
                      inputId={otherDriverMiddleNameQuestion.id}
                      formControllerName={otherDriverMiddleNameQuestion.name}
                      fieldName="middle name"
                      fieldLabel={otherDriverMiddleNameQuestion.label}
                      placeholderText="e.g. James"
                      autoCompleteCategory="middle-name"
                      maxLength={50}
                      optional={true}
                      optionalText={OptionalText.IfKnown}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LastNameInput
                      formControllerName={otherDriverLastNameQuestion.name}
                      label={otherDriverLastNameQuestion.label}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DateOfBirthInput
                      id={otherDriverDateOfBirthQuestion.id}
                      name={otherDriverDateOfBirthQuestion.name}
                      label={otherDriverDateOfBirthQuestion.label}
                      requiredMessage={pleaseEnterAValidMessage("date of birth")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactNumberInput
                      id={otherDriverContactNumberQuestion.id}
                      name={otherDriverContactNumberQuestion.name}
                      required={false}
                      optional={true}
                      label={otherDriverContactNumberQuestion.label}
                      optionalText={OptionalText.IfKnown}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AddressInput
                      name={otherDriverAddressQuestion.name}
                      label={otherDriverAddressQuestion.label}
                      required={false}
                      data={addressResults}
                      onSelection={onAddressSelection}
                      onInputChange={onAddressInputChange}
                      optionalText={OptionalText.IfKnown}
                      isLoading={addressInputIsLoading}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              id={nextButtonId}
              data-testid={nextButtonId}
              color="primary"
              variant="contained"
              fullWidth
              sx={{ margin: "24px 0 32px 0" }}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default DriverOfYourCarForm;
