import {
  ClaimsMotorCollisionApiException,
  ContactDetails,
  ContactDetailsPhoneNumberType,
} from "raci-claims-motor-collision-clientproxy";
import { HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { STORAGE_KEY_POLICY_NUMBER } from "../../../../shared/constants";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { ContactDetailsFormProps, ContactDetailsFormValues, ContactDetailsState, PhoneNumberTypes } from "../../types";

export const useContactDetails = (): ContactDetailsFormProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const [, setContactDetailsState] = useSessionState<ContactDetailsState>({
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<ContactDetailsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmit = async (state?: ContactDetailsFormValues) => {
    setContactDetailsState({ ...state, isCompleted: true });
    navigate(sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER) ? FormRoute.StartYourClaim : FormRoute.YourPolicy);
  };

  const apiClient = useBffApiClient();
  const [contactDetails, setContactDetails] = useState<ContactDetails>();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const getContactDetails = async () => {
      try {
        setBackdrop(true);
        const response = await apiClient.getContactDetails();
        const contactDetails = response.result as ContactDetails;
        setContactDetails(contactDetails);

        if (
          contactDetails.phoneNumberType === ContactDetailsPhoneNumberType.NoPhoneNumber ||
          response.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE
        ) {
          handleError(null, true, "GET /contact-details");
        }
      } catch (exception) {
        handleError(exception, false, "GET /contact-details");
      } finally {
        setIsLoading(false);
        setBackdrop(false);
      }
    };

    const handleError = (e: unknown, isMemberContactError: boolean, requestPath: string) => {
      const error = e as ClaimsMotorCollisionApiException;
      const status = error !== null ? error.status : "";

      const state = {
        referrer: location.pathname,
        exception: { request: requestPath, status },
        isMemberContactError: isMemberContactError,
      };

      navigate(ErrorRoute.SystemUnavailable, {
        state,
      });
    };

    getContactDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    form,
    isLoading,
    firstName: contactDetails?.firstName,
    phoneType: `${contactDetails?.phoneNumberType}` as PhoneNumberTypes,
    maskedPhoneNumber: contactDetails?.phoneNumber,
    maskedEmail: contactDetails?.email,
    onSubmit,
  };
};

export default useContactDetails;
