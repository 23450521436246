import { CardMedia, Grid, Typography } from "@mui/material";
import { ProvideTheLocation } from "../../../WhereAndHow/types";
import { ReviewYourClaimFormProps } from "../../types";

const WhereAndHowSummarySection = (summary: ReviewYourClaimFormProps["formSummaries"]["whereAndHow"]) => {
  return (
    <Grid container direction={"column"} spacing={1}>
      <Grid item>
        <Typography variant="body2" id="provide-the-location-label">
          <b>{summary.provideTheLocation.question}</b>
        </Typography>
        <Typography variant="body2" id="provide-the-location-content" sx={{ wordBreak: "break-all" }}>
          {summary.provideTheLocation.answer}
        </Typography>
      </Grid>
      {summary?.provideTheLocation?.answer === ProvideTheLocation.UseAMap && (
        <Grid item sx={{ overflow: "hidden", marginLeft: "8px", marginTop: "4px" }}>
          <CardMedia
            sx={{ transform: "scale(1.25)", marginLeft: "-3px", marginTop: "-23px" }}
            component="img"
            src={summary.whereTheIncidentHappenedMap.answer}
          />
        </Grid>
      )}
      {summary?.provideTheLocation?.answer === ProvideTheLocation.DescribeLocation && (
        <Grid item>
          <Typography variant="body2" id="where-the-incident-happened-label">
            <b>{summary.whereTheIncidentHappenedDescription.question}</b>
          </Typography>
          <Typography variant="body2" id="where-the-incident-happened-content" sx={{ wordBreak: "break-all" }}>
            {summary.whereTheIncidentHappenedDescription.answer}
          </Typography>
        </Grid>
      )}

      <Grid item>
        <Typography variant="body2" id="please-describe-what-happened-label">
          <b>{summary.howTheIncidentHappened.question}</b>
        </Typography>
        <Typography variant="body2" id="please-describe-what-happened-content" sx={{ wordBreak: "break-all" }}>
          {summary.howTheIncidentHappened.answer}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" id="were-the-police-involved-label">
          <b>{summary.werePoliceInvolved.question}</b>
        </Typography>
        <Typography variant="body2" id="were-the-police-involved-content" sx={{ wordBreak: "break-all" }}>
          {summary.werePoliceInvolved.answer}
        </Typography>
      </Grid>
      {summary?.policeReportNumber?.question && (
        <Grid item>
          <Typography variant="body2" id="police-report-number-label">
            <b>{summary.policeReportNumber.question}</b>
          </Typography>
          <Typography variant="body2" id="police-report-number-content" sx={{ wordBreak: "break-all" }}>
            {summary.policeReportNumber.answer}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default WhereAndHowSummarySection;
