import { event, gtm } from "@racwa/analytics";
import {
  ClaimsMotorCollisionApiException,
  ValidateClaimCreationErrorResponse,
  ValidateClaimCreationErrorResponseIneligibleReason,
} from "raci-claims-motor-collision-clientproxy";
import { HTTP_STATUS_CODE_BAD_REQUEST, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import useFlowState from "../../../../shared/components/FlowStateProvider/useFlowState";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { MoreAboutTheAccidentState } from "../../../MoreAboutTheAccident/types";
import { StartYourClaimFormProps, StartYourClaimFormValues, StartYourClaimState } from "../../types";

export const useStartYourClaim = (): StartYourClaimFormProps => {
  const navigate = useNavigateToRoute();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const [, setFlowCompleted] = useFlowState();
  const apiClient = useBffApiClient();
  const { claimNumber } = useGetSessionState<MoreAboutTheAccidentState>(FormRoute.MoreAboutTheAccident);
  const claimCreated = !!claimNumber;
  const [showSimilarClaimDialog, setShowSimilarClaimDialog] = useState(false);
  const [startYourClaimState, setStartYourClaimState] = useSessionState<StartYourClaimState>({
    skipPageTrackingRecalculation: claimCreated,
  });
  const [ineligibleForClaim, setIneligibleForClaim] = useState(
    !!startYourClaimState.error &&
      startYourClaimState.error.ineligibleReason !== ValidateClaimCreationErrorResponseIneligibleReason.SimilarClaim,
  );

  const form = useForm<StartYourClaimFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      date: startYourClaimState.date && new Date(startYourClaimState.date),
      time: startYourClaimState.time && new Date(startYourClaimState.time),
    },
  });

  const validateClaimCreation = async (newValues: StartYourClaimFormValues, allowSimilarClaim?: boolean) => {
    try {
      setBackdrop(true);
      const response = (await apiClient.validateClaimCreation(newValues.time, allowSimilarClaim)).result;
      setStartYourClaimState({
        ...startYourClaimState,
        ...newValues,
        ...response,
        error: undefined,
        isCompleted: true,
      });
      response.hasComprehensiveCover
        ? gtm(event("Cover type - Comprehensive"))
        : gtm(event("Cover type - Third party"));
      navigate(FormRoute.AboutTheAccident);
    } catch (exception) {
      const error = exception as ClaimsMotorCollisionApiException;
      if (error.status === HTTP_STATUS_CODE_BAD_REQUEST) {
        const result = error.result as ValidateClaimCreationErrorResponse;
        const responseState: StartYourClaimState = {
          ...newValues,
          ...startYourClaimState,
          isCompleted: result.ineligibleReason !== ValidateClaimCreationErrorResponseIneligibleReason.SimilarClaim,
          error: {
            ineligibleReason: result.ineligibleReason,
            duplicateOrSimilarClaimNumber: result.duplicateOrSimilarClaimNumber,
            // the generated dto has the date as javascript Date, but the returned value from the API is actually a string
            // @ts-expect-error
            similarClaimEventDate: result.similarClaimEventDate,
          },
        };

        setStartYourClaimState(responseState);

        if (result.ineligibleReason === ValidateClaimCreationErrorResponseIneligibleReason.SimilarClaim) {
          setShowSimilarClaimDialog(true);
        } else {
          setIneligibleForClaim(true);
          setFlowCompleted(FormRoute.StartYourClaim);
        }
      } else {
        navigate(ErrorRoute.SystemUnavailable, {
          state: {
            referrer: location.pathname,
            exception: { request: "POST /claims/validate-creation", status: error.status },
          },
        });
      }
    } finally {
      setBackdrop(false);
    }
  };

  const onSubmit: StartYourClaimFormProps["onSubmit"] = async (newValues) => {
    if (claimCreated) {
      return navigate(FormRoute.AboutTheAccident);
    }

    await validateClaimCreation(newValues);
  };

  const onSimilarClaimAllowed = async (newValues: StartYourClaimFormValues) => {
    gtm(event("Or make a new claim"));
    setShowSimilarClaimDialog(false);
    return await validateClaimCreation(newValues, true);
  };

  return {
    form,
    policyDetails: startYourClaimState.policyDetails,
    claimNumber,
    ineligibleForClaim,
    error: startYourClaimState.error,
    showSimilarClaimDialog,
    onSubmit,
    onSimilarClaimAllowed,
  };
};

export default useStartYourClaim;
