import {
  ClaimsMotorCollisionApiException,
  MoreThirdPartyDetailsRequest,
} from "raci-claims-motor-collision-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useClaimState from "../../../../shared/hooks/useClaimState";
import { useReferenceData } from "../../../../shared/hooks/useReferenceData";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { DriverWasTheOwnerYesNoUnknown } from "../../constants";
import {
  MoreThirdPartyDetailsFormProps,
  MoreThirdPartyDetailsFormValues,
  MoreThirdPartyDetailsState,
} from "../../types";

export const useMoreThirdPartyDetails = (): MoreThirdPartyDetailsFormProps => {
  const navigate = useNavigateToRoute();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const { isSingleVehicleCollisionClaim } = useClaimState();
  const { insuranceCompanies } = useReferenceData();
  const [moreThirdPartyDetailsState, setMoreThirdPartyDetailsState] = useSessionState<MoreThirdPartyDetailsState>();

  const form = useForm<MoreThirdPartyDetailsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: moreThirdPartyDetailsState,
    shouldUnregister: true,
  });

  const onSubmit: MoreThirdPartyDetailsFormProps["onSubmit"] = async (newValues) => {
    try {
      setBackdrop(true);

      if (answersHaveChanged({ newAnswers: newValues, oldAnswers: moreThirdPartyDetailsState })) {
        await apiClient.moreThirdPartyDetails(createRequest(newValues));
      }

      setMoreThirdPartyDetailsState({ ...newValues, isCompleted: true });

      navigate(FormRoute.WitnessDetails);
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /step/more-third-party-details`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    isSingleVehicleCollisionClaim,
    insuranceCompanies,
    onSubmit,
  };
};

const createRequest = (MoreThirdPartyDetailsFormValues: MoreThirdPartyDetailsFormValues) =>
  ({
    driverWasTheOwner: MoreThirdPartyDetailsFormValues.isDriverTheOwner
      ? DriverWasTheOwnerYesNoUnknown[MoreThirdPartyDetailsFormValues.isDriverTheOwner]
      : undefined,
    ownerNameAndContactDetails: MoreThirdPartyDetailsFormValues.ownersNameAndContactDetails,
    insuranceCompany: MoreThirdPartyDetailsFormValues.ownersInsuranceCompany,
    claimNumber: MoreThirdPartyDetailsFormValues.ownersClaimNumber,
    damageDescription: MoreThirdPartyDetailsFormValues.damageDescription,
  }) satisfies MoreThirdPartyDetailsRequest;

const answersHaveChanged = ({
  newAnswers,
  oldAnswers,
}: {
  newAnswers: MoreThirdPartyDetailsFormValues;
  oldAnswers: MoreThirdPartyDetailsState;
}) =>
  newAnswers.isDriverTheOwner !== oldAnswers.isDriverTheOwner ||
  newAnswers.ownersNameAndContactDetails !== oldAnswers.ownersNameAndContactDetails ||
  newAnswers.ownersClaimNumber !== oldAnswers.ownersClaimNumber ||
  newAnswers.ownersInsuranceCompany?.externalCode !== oldAnswers.ownersInsuranceCompany?.externalCode ||
  newAnswers.damageDescription !== oldAnswers.damageDescription;

export default useMoreThirdPartyDetails;
