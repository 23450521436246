import { AboutYourCarRequest, ClaimsMotorCollisionApiException } from "raci-claims-motor-collision-clientproxy";
import { YesNoImNotSure, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useBffApiClient } from "../../../shared/hooks/useApiClient";
import { useReferenceData } from "../../../shared/hooks/useReferenceData";
import { ErrorRoute, FormRoute } from "../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../shared/routing/useNavigateToRoute";
import { WheresYourCarState } from "../../WheresYourCar/types";
import { CarIsDriveableYesNoImNotSure, CarWasTowedYesNoImNotSure } from "../constants";
import { AboutYourCarFormValues, AboutYourCarProps, AboutYourCarState } from "../types";

export const useAboutYourCar = (): AboutYourCarProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const location = useLocation();
  const { suburbs } = useReferenceData();
  const [, setWheresYourCarState] = useSessionState<WheresYourCarState>({ specificKey: FormRoute.WheresYourCar });
  const [aboutYourCarState, setAboutYourCarState] = useSessionState<AboutYourCarState>();

  const form = useForm<AboutYourCarFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: aboutYourCarState,
  });

  const onSubmit: AboutYourCarProps["onSubmit"] = async (newValues) => {
    const carWasTowedHasChanged = newValues.carWasTowed !== aboutYourCarState.carWasTowed;

    newValues.preferredSuburbForRepairs = newValues.findRepairerOutsideWA
      ? undefined
      : newValues.preferredSuburbForRepairs;

    try {
      setBackdrop(true);

      if (answersHaveChanged({ newAnswers: newValues, oldAnswers: aboutYourCarState })) {
        await apiClient.aboutYourCar(createRequest(newValues));
      }

      setAboutYourCarState({
        ...newValues,
        isCompleted: true,
      });

      if (newValues.carIsDriveable === YesNoImNotSure.Yes) {
        setWheresYourCarState({ isCompleted: true });
        navigate(FormRoute.ReviewYourClaim);
      } else {
        carWasTowedHasChanged && setWheresYourCarState({ isCompleted: false });
        navigate(FormRoute.WheresYourCar);
      }
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /step/about-your-car`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };
  return {
    form,
    onSubmit,
    suburbs,
  };
};

const answersHaveChanged = ({
  newAnswers,
  oldAnswers,
}: {
  newAnswers: AboutYourCarFormValues;
  oldAnswers: AboutYourCarState;
}) =>
  newAnswers.damageDescription !== oldAnswers.damageDescription ||
  newAnswers.carWasTowed !== oldAnswers.carWasTowed ||
  newAnswers.carIsDriveable !== oldAnswers.carIsDriveable ||
  newAnswers.preferredSuburbForRepairs?.cityName !== oldAnswers.preferredSuburbForRepairs?.cityName ||
  newAnswers.findRepairerOutsideWA !== oldAnswers.findRepairerOutsideWA;

const createRequest = (formValues: AboutYourCarFormValues) =>
  ({
    ...formValues,
    carWasTowed: CarWasTowedYesNoImNotSure[formValues.carWasTowed],
    carIsDriveable: formValues.carIsDriveable && CarIsDriveableYesNoImNotSure[formValues.carIsDriveable],
  }) satisfies AboutYourCarRequest;

export default useAboutYourCar;
