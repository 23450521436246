import { event, gtm } from "@racwa/analytics";
import { useTooltip } from "raci-react-library";
import { useEffect } from "react";
import {
  HeldValidLicenceGtmDescription,
  LicenceSuspendedGtmDescription,
  UnderTheInfluenceGtmDescription,
} from "../../constants";

export const useDriverHistoryTooltips = () => {
  const driverWasUnderTheInfluenceTooltipProps = useTooltip({
    title: "Under the influence",
    message: "This includes anything that can affect your driving ability.",
  });
  const driverHasValidLicenceTooltipProps = useTooltip({
    title: "Licence held",
    message: "This 2-year period doesn't include the time a learner's permit was held.",
  });
  const driverHasLicenceSuspensionOrCancellationTooltipProps = useTooltip({
    title: "Driver's licence",
    message: "This includes licence suspension or cancellation due to losing all demerit points.",
  });

  useEffect(() => {
    if (driverWasUnderTheInfluenceTooltipProps.open) {
      gtm(event(`Tooltip: ${UnderTheInfluenceGtmDescription}`));
    } else if (driverHasValidLicenceTooltipProps.open) {
      gtm(event(`Tooltip: ${HeldValidLicenceGtmDescription}`));
    } else if (driverHasLicenceSuspensionOrCancellationTooltipProps.open) {
      gtm(event(`Tooltip: ${LicenceSuspendedGtmDescription}`));
    }
  }, [
    driverWasUnderTheInfluenceTooltipProps.open,
    driverHasValidLicenceTooltipProps.open,
    driverHasLicenceSuspensionOrCancellationTooltipProps.open,
  ]);

  return {
    driverWasUnderTheInfluenceTooltipProps,
    driverHasValidLicenceTooltipProps,
    driverHasLicenceSuspensionOrCancellationTooltipProps,
  };
};

export default useDriverHistoryTooltips;
