import { ClaimsMotorCollisionApiException, ThirdPartyDetailsRequest } from "raci-claims-motor-collision-clientproxy";
import { YesNo, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import useFlowState from "../../../../shared/components/FlowStateProvider/useFlowState";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useClaimState from "../../../../shared/hooks/useClaimState";
import useSearchAddress from "../../../../shared/hooks/useSearchAddress";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { MoreThirdPartyDetailsState } from "../../../MoreThirdPartyDetails/types";
import { IsThirdPartyKnownToPolicyHolderYesNo } from "../../constants";
import { ThirdPartyDetailsFormProps, ThirdPartyDetailsFormValues, ThirdPartyDetailsState } from "../../types";

export const useThirdPartyDetails = (): ThirdPartyDetailsFormProps => {
  const navigate = useNavigateToRoute();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const addressInputProps = useSearchAddress();
  const { isLiabilityOnlyClaim, isSingleVehicleCollisionClaim } = useClaimState();
  const [{ isFlowCompleted }, setFlowCompleted] = useFlowState();
  const [thirdPartyDetailsState, setThirdPartyDetailsState] = useSessionState<ThirdPartyDetailsState>();
  const [, setMoreThirdPartyDetailsState] = useSessionState<MoreThirdPartyDetailsState>({
    specificKey: FormRoute.MoreThirdPartyDetails,
  });

  const form = useForm<ThirdPartyDetailsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: thirdPartyDetailsState,
  });

  const onSubmit: ThirdPartyDetailsFormProps["onSubmit"] = async (newValues) => {
    if (isLiabilityOnlyClaim && newValues.hasOwnerDetails === YesNo.No) {
      setThirdPartyDetailsState({ hasOwnerDetails: YesNo.No, isCompleted: true });
      return setFlowCompleted(FormRoute.ThirdPartyDetails);
    }

    try {
      setBackdrop(true);

      if (answersHaveChanged({ newAnswers: newValues, oldAnswers: thirdPartyDetailsState })) {
        await apiClient.thirdPartyDetails(createRequest(newValues));
      }

      if (newValues.hasOwnerDetails === YesNo.Yes) {
        setThirdPartyDetailsState({ ...newValues, isCompleted: true });

        navigate(FormRoute.MoreThirdPartyDetails);
      } else {
        setThirdPartyDetailsState({ hasOwnerDetails: YesNo.No, isCompleted: true });
        setMoreThirdPartyDetailsState({ isCompleted: true });

        navigate(FormRoute.WitnessDetails);
      }
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /step/third-party-details`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    addressInputProps,
    isLiabilityOnlyClaim,
    isSingleVehicleCollisionClaim,
    youCantClaimOnline: isFlowCompleted,
    onSubmit,
  };
};

const createRequest = (thirdPartyDetailsState: ThirdPartyDetailsState) =>
  ({
    hasOwnerDetails: thirdPartyDetailsState.hasOwnerDetails === YesNo.Yes,
    carRegistration: thirdPartyDetailsState.thirdPartyCarRegistration,
    firstName: thirdPartyDetailsState.thirdPartyFirstName,
    lastName: thirdPartyDetailsState.thirdPartyLastName,
    contactNumber: thirdPartyDetailsState.thirdPartyContactNumber,
    email: thirdPartyDetailsState.thirdPartyEmail,
    addressMoniker: thirdPartyDetailsState.thirdPartyAddress?.moniker,
    isThirdPartyKnownToPolicyHolder: thirdPartyDetailsState.isThirdPartyKnownToPolicyHolder
      ? IsThirdPartyKnownToPolicyHolderYesNo[thirdPartyDetailsState.isThirdPartyKnownToPolicyHolder!]
      : undefined,
  }) satisfies ThirdPartyDetailsRequest;

const answersHaveChanged = ({
  newAnswers,
  oldAnswers,
}: {
  newAnswers: ThirdPartyDetailsFormValues;
  oldAnswers: ThirdPartyDetailsState;
}) =>
  newAnswers.hasOwnerDetails !== oldAnswers.hasOwnerDetails ||
  newAnswers.thirdPartyCarRegistration !== oldAnswers.thirdPartyCarRegistration ||
  newAnswers.thirdPartyFirstName !== oldAnswers.thirdPartyFirstName ||
  newAnswers.thirdPartyLastName !== oldAnswers.thirdPartyLastName ||
  newAnswers.thirdPartyContactNumber !== oldAnswers.thirdPartyContactNumber ||
  newAnswers.thirdPartyEmail !== oldAnswers.thirdPartyEmail ||
  (newAnswers.thirdPartyAddress?.displayAddress !== oldAnswers.thirdPartyAddress?.displayAddress &&
    newAnswers.thirdPartyAddress?.moniker !== oldAnswers.thirdPartyAddress?.moniker) ||
  newAnswers.isThirdPartyKnownToPolicyHolder !== oldAnswers.isThirdPartyKnownToPolicyHolder;

export default useThirdPartyDetails;
