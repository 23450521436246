import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import {
  RacwaEmailInput,
  RacwaFirstNameInput,
  RacwaFormControl,
  RacwaLastNameInput,
  RacwaPhoneInput,
} from "@racwa/react-components";
import { colors } from "@racwa/styles";
import {
  AllErrorMessage as ContactNumberHelperText,
  AllValidationPattern as ContactNumberValidationPattern,
  EmailSizeLimit,
  EmailValidationPattern,
  FirstNameSizeLimit,
  LastNameSizeLimit,
  NameValidationPattern,
  NotificationCard,
  OptionalText,
  pleaseEnterAValidMessage,
} from "raci-react-library";
import { useState } from "react";
import { useController, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { WitnessDetailsQuestions } from "../../constants";
import { WitnessDetailsFormValues } from "../../types";

const {
  witnesses: { id, name, label, subLabel },
} = WitnessDetailsQuestions;

const getWitnessId = (index?: number, suffix?: string) => `${id}${index}${suffix ? `-${suffix}` : ""}`;

export const WitnessDetailList = () => {
  const { control } = useFormContext<WitnessDetailsFormValues>();
  const { fields, append, remove } = useFieldArray<WitnessDetailsFormValues>({ control, name: "witnesses" });

  return (
    <RacwaFormControl label={label} sublabel={subLabel} fullWidth>
      {fields.length > 0 &&
        fields.map((item, index) => (
          <Box
            key={item.id}
            id={getWitnessId(index)}
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "24px",
              border: `1px solid ${colors.dieselLight}`,
              borderBottom: index !== fields.length - 1 ? "none" : `1px solid ${colors.dieselLight}`,
              borderRadius: "4px",
            }}
          >
            <Typography variant="h2" sx={{ paddingBottom: "8px" }}>
              {`Witness ${index + 1}`}
            </Typography>
            <FirstNameInput key={item.id} index={index} />
            <LastNameInput key={item.id} index={index} />
            <Box sx={{ paddingTop: "16px", paddingBottom: "8px" }}>
              <Divider sx={{ borderColor: colors.dieselLight }} />
              <Typography sx={{ paddingTop: "24px" }}>
                <b>Please provide at least one contact method</b>
              </Typography>
            </Box>
            <ContactDetailsInputs key={item.id} index={index} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                id={getWitnessId(index, "delete-witness")}
                variant="text"
                startIcon={<FontAwesomeIcon icon={faTrash} style={{ fontSize: "16px" }} />}
                onClick={() => {
                  remove(index);
                  gtm(event("Delete item"));
                }}
                sx={{ color: colors.linkBlue, marginTop: "16px", padding: "0px" }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "400", textDecoration: "underline" }}>
                  Delete item
                </Typography>
              </Button>
            </Box>
          </Box>
        ))}
      {fields.length > 1 ? (
        <Box sx={{ paddingTop: "16px" }}>
          <NotificationCard
            severity="info"
            message={{
              title: "You can email us if you'd like to provide more witness details",
            }}
          />
        </Box>
      ) : (
        <Button
          id={`${id}-add-witness`}
          color="secondary"
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faAdd} style={{ fontSize: "18px" }} />}
          sx={{ marginTop: `${fields.length > 0 ? "16" : "0"}px` }}
          onClick={() => {
            append({ firstName: "", lastName: "", contactNumber: "", email: "" });
            gtm(event("Add witness"));
          }}
          fullWidth
        >
          Add witness
        </Button>
      )}
    </RacwaFormControl>
  );
};

const FirstNameInput = ({ index }: { index: number }) => {
  const helperText = pleaseEnterAValidMessage("first name");
  const { control } = useFormContext<WitnessDetailsFormValues>();
  const { field, fieldState } = useController<WitnessDetailsFormValues, `${typeof name}.${typeof index}.firstName`>({
    control,
    name: `${name}.${index}.firstName`,
    rules: {
      required: {
        value: true,
        message: helperText,
      },
      pattern: {
        value: NameValidationPattern,
        message: helperText,
      },
    },
  });

  return (
    <RacwaFirstNameInput
      {...field}
      id={getWitnessId(index, "first-name")}
      label="First name"
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      inputProps={{
        maxLength: FirstNameSizeLimit,
      }}
      onBlur={() => {
        gtm(fieldTouched("First name"));
      }}
    />
  );
};

const LastNameInput = ({ index }: { index: number }) => {
  const helperText = pleaseEnterAValidMessage("last name");
  const { control } = useFormContext<WitnessDetailsFormValues>();
  const { field, fieldState } = useController<WitnessDetailsFormValues, `${typeof name}.${typeof index}.lastName`>({
    control,
    name: `${name}.${index}.lastName`,
    rules: {
      pattern: {
        value: NameValidationPattern,
        message: helperText,
      },
    },
  });

  return (
    <RacwaLastNameInput
      {...field}
      id={getWitnessId(index, "last-name")}
      label="Last name"
      optional
      optionalText={OptionalText.IfKnown}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      inputProps={{
        maxLength: LastNameSizeLimit,
      }}
      onBlur={() => {
        gtm(fieldTouched("Last name"));
      }}
    />
  );
};

const ContactDetailsInputs = ({ index }: { index: number }) => {
  const { control, clearErrors } = useFormContext<WitnessDetailsFormValues>();
  const witnesses = useWatch({ control, name: "witnesses" });
  const [contactNumberHelperText, setContactNumberHelperText] = useState("");

  const witnessDetail = witnesses[`${index}`];
  const contactNumberName = `${name}.${index}.contactNumber` as const;
  const emailName = `${name}.${index}.email` as const;
  const emailErrorMessage = pleaseEnterAValidMessage("email");

  const {
    field: { onChange: contactNumberFieldOnChange, ...contactNumberField },
    fieldState: contactNumberFieldState,
  } = useController<WitnessDetailsFormValues, `${typeof name}.${typeof index}.contactNumber`>({
    control,
    name: contactNumberName,
    rules: {
      required: !witnessDetail?.email,
      pattern: ContactNumberValidationPattern,
    },
  });

  const { field: emailField, fieldState: emailFieldState } = useController<
    WitnessDetailsFormValues,
    `${typeof name}.${typeof index}.email`
  >({
    control,
    name: emailName,
    rules: {
      required: {
        value: !witnessDetail?.contactNumber,
        message: emailErrorMessage,
      },
      maxLength: {
        value: EmailSizeLimit,
        message: emailErrorMessage,
      },
      pattern: {
        value: EmailValidationPattern,
        message: emailErrorMessage,
      },
    },
  });

  return (
    <>
      <RacwaPhoneInput
        {...contactNumberField}
        id={getWitnessId(index, "contact-number")}
        label="Contact number"
        error={!!contactNumberFieldState.error}
        helperText={contactNumberFieldState.error ? ContactNumberHelperText : contactNumberHelperText}
        onFocus={() => !contactNumberField.value && setContactNumberHelperText(ContactNumberHelperText)}
        onValueChange={({ value }) => {
          contactNumberFieldOnChange(value);
          if (value) {
            setContactNumberHelperText("");
            !emailField.value && emailFieldState.invalid && clearErrors(emailName);
          }
        }}
        onBlur={() => {
          gtm(fieldTouched("Contact number"));
        }}
      />
      <RacwaEmailInput
        {...emailField}
        id={getWitnessId(index, "email")}
        label="Email"
        error={!!emailFieldState.error}
        helperText={emailFieldState.error?.message}
        inputProps={{ maxLength: EmailSizeLimit, type: "text" }}
        onChange={({ currentTarget: { value } }) => {
          emailField.onChange(value);
          if (value && !contactNumberField.value && contactNumberFieldState.invalid) {
            clearErrors(contactNumberName);
          }
        }}
        onBlur={() => {
          gtm(fieldTouched("Email"));
        }}
      />
    </>
  );
};

export default WitnessDetailList;
