import { PolicyDetails } from "raci-claims-motor-collision-clientproxy";
import { PolicyOption } from "raci-react-library";

export const getPolicyOption = (policyDetails: PolicyDetails): PolicyOption => ({
  policyNumber: policyDetails?.policyNumber ?? "",
  policyTitle: `${policyDetails.year} ${policyDetails.make}`,
  policySubtitle: policyDetails?.registrationNumber ?? "",
});

export default getPolicyOption;
