import { YesNo, YesNoUnknown, formatPhoneNumber } from "raci-react-library";
import { ClaimState } from "../../../../../../shared/hooks/useClaimState";
import { AboutTheAccidentState } from "../../../../../AboutTheAccident/types";
import { ThirdPartyDetailsQuestions } from "../../../../../ThirdPartyDetails/constants";
import { ThirdPartyDetailsFormValues, ThirdPartyDetailsState } from "../../../../../ThirdPartyDetails/types";
import { FormSummary } from "../../../../types";

type Args = Pick<ClaimState, "isSingleVehicleCollisionClaim"> &
  Pick<AboutTheAccidentState, "propertyDamaged"> &
  Pick<ThirdPartyDetailsState, keyof ThirdPartyDetailsFormValues>;

export const summariseThirdPartyDetails = ({
  isSingleVehicleCollisionClaim,
  propertyDamaged,
  hasOwnerDetails,
  thirdPartyCarRegistration,
  thirdPartyFirstName,
  thirdPartyLastName,
  thirdPartyContactNumber,
  thirdPartyEmail,
  thirdPartyAddress,
  isThirdPartyKnownToPolicyHolder,
}: Args) => {
  if (isSingleVehicleCollisionClaim && propertyDamaged !== YesNoUnknown.Yes) {
    return undefined;
  }

  if (hasOwnerDetails === YesNo.No) {
    return {
      hasOwnerDetails: {
        question: ThirdPartyDetailsQuestions.hasOwnerDetails.label,
        answer: YesNo.No,
      },
      thirdPartyCarRegistration: undefined,
      thirdPartyFirstName: undefined,
      thirdPartyLastName: undefined,
      thirdPartyContactNumber: undefined,
      thirdPartyEmail: undefined,
      thirdPartyAddress: undefined,
      isThirdPartyKnownToPolicyHolder: undefined,
    } as const satisfies FormSummary<ThirdPartyDetailsFormValues>;
  }

  return {
    hasOwnerDetails: {
      question: ThirdPartyDetailsQuestions.hasOwnerDetails.label,
      answer: YesNo.Yes,
    },
    thirdPartyCarRegistration: !isSingleVehicleCollisionClaim
      ? {
          question: ThirdPartyDetailsQuestions.thirdPartyCarRegistration.label,
          answer: thirdPartyCarRegistration || "-",
        }
      : undefined,
    thirdPartyFirstName: {
      question: ThirdPartyDetailsQuestions.thirdPartyFirstName.label,
      answer: thirdPartyFirstName || "-",
      customId: ThirdPartyDetailsQuestions.thirdPartyFirstName.id,
    },
    thirdPartyLastName: {
      question: ThirdPartyDetailsQuestions.thirdPartyLastName.label,
      answer: thirdPartyLastName || "-",
      customId: ThirdPartyDetailsQuestions.thirdPartyLastName.id,
    },
    thirdPartyContactNumber: {
      question: ThirdPartyDetailsQuestions.thirdPartyContactNumber.label,
      answer: formatPhoneNumber(thirdPartyContactNumber || "-"),
      customId: ThirdPartyDetailsQuestions.thirdPartyContactNumber.id,
    },
    thirdPartyEmail: {
      question: ThirdPartyDetailsQuestions.thirdPartyEmail.label,
      answer: thirdPartyEmail || "-",
      customId: ThirdPartyDetailsQuestions.thirdPartyEmail.id,
    },
    thirdPartyAddress: {
      question: ThirdPartyDetailsQuestions.thirdPartyAddress.label,
      answer: thirdPartyAddress?.displayAddress || "-",
      customId: ThirdPartyDetailsQuestions.thirdPartyAddress.id,
    },
    isThirdPartyKnownToPolicyHolder: {
      question: ThirdPartyDetailsQuestions.isThirdPartyKnownToPolicyHolder.label,
      answer: isThirdPartyKnownToPolicyHolder,
    },
  } as const satisfies FormSummary<ThirdPartyDetailsFormValues>;
};

export default summariseThirdPartyDetails;
