import { FormQuestions } from "../../shared/types";
import { WitnessDetailsFormValues } from "./types";

export const witnessDetailsPageTitle = "Witnesses";

export const WitnessDetailsQuestions = {
  witnesses: {
    id: "witnessesQuestion",
    label: "Add witness contact details",
    subLabel: "Up to two.",
    name: "witnesses",
  },
} satisfies FormQuestions<WitnessDetailsFormValues>;
