import {
  CreateClaimRequestCollisionScenario,
  CreateClaimRequestDirectionOfTravel,
} from "raci-claims-motor-collision-clientproxy";
import { RadioButtonGroupItem } from "raci-react-library";

const hitAParkedVehicle = "Hit a parked vehicle";
const failToGiveWay = "Fail to give way";
export const somethingElseHappened = "Something else happened";

export enum ForwardScenario {
  AVehicleHitYourCar = "A vehicle hit your car",
  YourCarHitAnotherVehicle = "Your car hit another vehicle",
  OurVehiclesHitOneAnother = "Our vehicles hit one another",
  SomethingElseHappened = somethingElseHappened,
}

export const getForwardScenarios = (isLiabilityOnlyClaim: boolean) =>
  forwardScenarios.filter(
    (s) =>
      !isLiabilityOnlyClaim ||
      s.label === ForwardScenario.YourCarHitAnotherVehicle ||
      s.label === ForwardScenario.SomethingElseHappened,
  );

export const getMultiVehicleCollisionScenarioRadioGroupItems = (
  isLiabilityOnlyClaim: boolean,
  directionOfTravel: CreateClaimRequestDirectionOfTravel,
  forwardScenario?: ForwardScenario,
) => {
  if (forwardScenario) {
    return forwardCollisionScenarios[`${forwardScenario}`](isLiabilityOnlyClaim);
  }

  switch (directionOfTravel) {
    case CreateClaimRequestDirectionOfTravel.Reversing:
      return reversingCollisionScenarios.filter(
        (s) =>
          !isLiabilityOnlyClaim ||
          s.label === "Hit a parked vehicle" ||
          s.label === "Hit another vehicle" ||
          s.label === "Something else happened",
      );
    case CreateClaimRequestDirectionOfTravel.Parked:
      return isLiabilityOnlyClaim ? [] : parkedCollisionScenarios;
    case CreateClaimRequestDirectionOfTravel.Stationary:
      return isLiabilityOnlyClaim ? [] : stationaryCollisionScenarios;
    default:
      return [];
  }
};

const forwardScenarios = Object.values(ForwardScenario).map(
  (v, i) => ({ id: i.toString(), value: v, label: v }) as const satisfies RadioButtonGroupItem,
);

const forwardCollisionScenarios = {
  "A vehicle hit your car": () => [
    {
      id: "0",
      value: CreateClaimRequestCollisionScenario.HitByVehicleInRear,
      label: "Hit the rear of your car",
    } as const,
    {
      id: "1",
      value: CreateClaimRequestCollisionScenario.HitByVehicleThatWasChangingLanes,
      label: "Hit your car when changing lanes",
    } as const,
    {
      id: "2",
      value: CreateClaimRequestCollisionScenario.HitByVehicleThatFailedToGiveWay,
      label: failToGiveWay,
    } as const,
    {
      id: "3",
      value: CreateClaimRequestCollisionScenario.SomethingElseHappened,
      label: somethingElseHappened,
    } as const,
  ],
  "Your car hit another vehicle": (isLiabilityOnlyClaim) =>
    (
      [
        {
          id: "0",
          value: CreateClaimRequestCollisionScenario.HitAnotherVehicleWhenChangingLanes,
          label: "Hit another vehicle when changing lanes",
        } as const,
        {
          id: "1",
          value: CreateClaimRequestCollisionScenario.HitAnotherVehicleInRear,
          label: "Hit the rear of another vehicle",
        } as const,
        {
          id: "2",
          value: CreateClaimRequestCollisionScenario.HitAParkedVehicle,
          label: hitAParkedVehicle,
        } as const,
        {
          id: "3",
          value: CreateClaimRequestCollisionScenario.HitAnotherVehicleWhenFailingToGiveWay,
          label: failToGiveWay,
        } as const,
        {
          id: "4",
          value: CreateClaimRequestCollisionScenario.SomethingElseHappened,
          label: somethingElseHappened,
        } as const,
      ] as const
    ).filter((scenario) => !isLiabilityOnlyClaim || scenario.label !== "Fail to give way"),
  "Our vehicles hit one another": () => [
    {
      id: "0",
      value: CreateClaimRequestCollisionScenario.BothVehiclesChangedLanesAndHitEachOther,
      label: "Both changed lanes and hit each other",
    } as const,
    {
      id: "1",
      value: CreateClaimRequestCollisionScenario.BothVehiclesFailedToGiveWayAndHitEachOther,
      label: "Both failed to give way",
    } as const,
    {
      id: "2",
      value: CreateClaimRequestCollisionScenario.SomethingElseHappened,
      label: somethingElseHappened,
    } as const,
  ],
  "Something else happened": () => [],
} as const satisfies Record<
  (typeof forwardScenarios)[number]["value"],
  (isLiabilityOnlyClaim: boolean) => RadioButtonGroupItem[]
>;

const reversingCollisionScenarios = [
  {
    id: "0",
    value: CreateClaimRequestCollisionScenario.HitAParkedVehicle,
    label: hitAParkedVehicle,
  } as const,
  {
    id: "1",
    value: CreateClaimRequestCollisionScenario.HitAnotherVehicle,
    label: "Hit another vehicle",
  } as const,
  {
    id: "2",
    value: CreateClaimRequestCollisionScenario.HitByVehicleInRear,
    label: "Get hit in the rear by another vehicle",
  } as const,
  {
    id: "3",
    value: CreateClaimRequestCollisionScenario.BothVehiclesReversedIntoEachOther,
    label: "We reversed into each other",
  } as const,
  {
    id: "4",
    value: CreateClaimRequestCollisionScenario.SomethingElseHappened,
    label: somethingElseHappened,
  } as const,
] as const satisfies RadioButtonGroupItem[];

const parkedCollisionScenarios = [
  {
    id: "0",
    value: CreateClaimRequestCollisionScenario.HitByVehicle,
    label: "Yes",
  } as const,
  {
    id: "1",
    value: CreateClaimRequestCollisionScenario.SomethingElseHappened,
    label: somethingElseHappened,
  } as const,
] as const satisfies RadioButtonGroupItem[];

const stationaryCollisionScenarios = [
  {
    id: "0",
    value: CreateClaimRequestCollisionScenario.HitByVehicleInRear,
    label: "Another driver hit the rear of my car",
  } as const,
  {
    id: "1",
    value: CreateClaimRequestCollisionScenario.HitByVehicleThatWasReversing,
    label: "Another driver reversed into my car",
  } as const,
  {
    id: "2",
    value: CreateClaimRequestCollisionScenario.SomethingElseHappened,
    label: somethingElseHappened,
  } as const,
] as const satisfies RadioButtonGroupItem[];
