import { FormQuestions } from "../../shared/types";
import { ContactDetailsFormValues } from "./types";

export const contactDetailsPageTitle = "Contact details";

export const ContactDetailsQuestions = {
  contactNumber: {
    id: "contact-number",
    name: "contactNumber",
    label: "Contact number",
  },
  email: {
    id: "email",
    name: "email",
    label: "Email",
  },
} as const satisfies FormQuestions<ContactDetailsFormValues>;
