import { ClaimsMotorCollisionApiException, DriverHistoryRequest } from "raci-claims-motor-collision-clientproxy";
import { YesNoUnknown, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useClaimState from "../../../../shared/hooks/useClaimState";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { AboutTheAccidentState } from "../../../AboutTheAccident/types";
import { DriverOfYourCarState } from "../../../DriverOfYourCar/types";
import { MoreThirdPartyDetailsState } from "../../../MoreThirdPartyDetails/types";
import { ThirdPartyDetailsState } from "../../../ThirdPartyDetails/types";
import {
  DriverHasLicenceSuspensionOrCancellationYesNoUnknown,
  DriverHasValidLicenceYesNoUnknown,
  DriverWasUnderTheInfluenceYesNoUnknown,
} from "../../constants";
import { DriverHistoryFormProps, DriverHistoryFormValues, DriverHistoryState } from "../../types";

export const useDriverHistory = (): DriverHistoryFormProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const location = useLocation();
  const { driverName } = useGetSessionState<DriverOfYourCarState>(FormRoute.DriverOfYourCar);
  const { isSingleVehicleCollisionClaim } = useClaimState();
  const { propertyDamaged } = useGetSessionState<AboutTheAccidentState>(FormRoute.AboutTheAccident);
  const [driverHistoryState, setDriverHistoryState] = useSessionState<DriverHistoryState>();
  const [, setThirdPartyDetailsState] = useSessionState<ThirdPartyDetailsState>({
    specificKey: FormRoute.ThirdPartyDetails,
  });
  const [, setMoreThirdPartyDetailsState] = useSessionState<MoreThirdPartyDetailsState>({
    specificKey: FormRoute.MoreThirdPartyDetails,
  });

  const form = useForm<DriverHistoryFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: driverHistoryState,
    // When running locally:
    // 'shouldUnregister: true' may cause conditional inputs to flicker if they are shown on the initial render of the form.
    // This is due to react-hook-form's 'watch' method using useEffect and React18's <StrictMode /> running useEffect twice.
    shouldUnregister: true,
  });

  const onSubmit: DriverHistoryFormProps["onSubmit"] = async (newValues) => {
    try {
      setBackdrop(true);

      if (answersHaveChanged({ newAnswers: newValues, oldAnswers: driverHistoryState })) {
        await apiClient.driverHistory(createRequest(newValues));
      }

      setDriverHistoryState({
        ...newValues,
        isCompleted: true,
      });

      if (!(isSingleVehicleCollisionClaim && propertyDamaged !== YesNoUnknown.Yes)) {
        navigate(FormRoute.ThirdPartyDetails);
      } else {
        setThirdPartyDetailsState({ isCompleted: true });
        setMoreThirdPartyDetailsState({ isCompleted: true });
        navigate(FormRoute.WitnessDetails);
      }
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /step/driver-history`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    driverName,
    onSubmit,
  };
};

const answersHaveChanged = ({
  newAnswers,
  oldAnswers,
}: {
  newAnswers: DriverHistoryFormValues;
  oldAnswers: DriverHistoryState;
}) =>
  newAnswers.driverWasUnderTheInfluence !== oldAnswers.driverWasUnderTheInfluence ||
  newAnswers.driverHasValidLicence !== oldAnswers.driverHasValidLicence ||
  newAnswers.driverHasLicenceSuspensionOrCancellation !== oldAnswers.driverHasLicenceSuspensionOrCancellation ||
  newAnswers.suspensionOrCancellationDetails !== oldAnswers.suspensionOrCancellationDetails;

const createRequest = (formValues: DriverHistoryFormValues) =>
  ({
    ...formValues,
    driverWasUnderTheInfluence: DriverWasUnderTheInfluenceYesNoUnknown[formValues.driverWasUnderTheInfluence],
    driverHasValidLicence: DriverHasValidLicenceYesNoUnknown[formValues.driverHasValidLicence],
    driverHasLicenceSuspensionOrCancellation:
      DriverHasLicenceSuspensionOrCancellationYesNoUnknown[formValues.driverHasLicenceSuspensionOrCancellation],
  }) satisfies DriverHistoryRequest;

export default useDriverHistory;
