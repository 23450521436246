import { ClaimState } from "../../../../../../shared/hooks/useClaimState";
import { AboutYourCarQuestions } from "../../../../../AboutYourCar/constants";
import { AboutYourCarFormValues, AboutYourCarState } from "../../../../../AboutYourCar/types";
import { FormSummary } from "../../../../types";

type Args = Pick<ClaimState, "isLiabilityOnlyClaim"> & Pick<AboutYourCarState, keyof AboutYourCarFormValues>;

export const summariseAboutYourCar = ({
  isLiabilityOnlyClaim,
  damageDescription,
  carWasTowed,
  carIsDriveable,
  preferredSuburbForRepairs,
  findRepairerOutsideWA,
}: Args) => {
  if (isLiabilityOnlyClaim) {
    return undefined;
  }

  return {
    damageDescription: {
      question: AboutYourCarQuestions.damageDescription.label,
      answer: damageDescription,
    },
    carWasTowed: carWasTowed
      ? {
          question: AboutYourCarQuestions.carWasTowed.label,
          answer: carWasTowed,
        }
      : undefined,
    carIsDriveable: carIsDriveable
      ? {
          question: AboutYourCarQuestions.carIsDriveable.label,
          answer: carIsDriveable,
        }
      : undefined,
    preferredSuburbForRepairs: preferredSuburbForRepairs
      ? {
          question: AboutYourCarQuestions.preferredSuburbForRepairs.label,
          answer: preferredSuburbForRepairs?.cityName,
        }
      : undefined,
    findRepairerOutsideWA: findRepairerOutsideWA
      ? {
          question: AboutYourCarQuestions.findRepairerOutsideWA.label,
          answer: findRepairerOutsideWA ? "Yes" : "No",
        }
      : undefined,
  } as const satisfies FormSummary<AboutYourCarFormValues>;
};

export default summariseAboutYourCar;
