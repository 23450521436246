import { ClaimsMotorCollisionApiException } from "raci-claims-motor-collision-clientproxy";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useIsClaimCreated from "../../../../shared/hooks/useIsClaimCreated";
import { ErrorRoute, FormRoute, PreFormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import getPolicyOption from "../../../../shared/utils/getPolicyOption";
import { AboutTheAccidentState } from "../../../AboutTheAccident/types";
import { BeforeYouStartState } from "../../../BeforeYouStart/types";
import { StartYourClaimState } from "../../../StartYourClaim/types";
import { YourPolicyFormProps, YourPolicyFormValues, YourPolicyState } from "../../types";

export const useYourPolicy = (): YourPolicyFormProps => {
  const location = useLocation();
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const claimCreated = useIsClaimCreated();
  const beforeYouStartState = useGetSessionState<BeforeYouStartState>(PreFormRoute.BeforeYouStart);
  const [yourPolicyState, setYourPolicyState] = useSessionState<YourPolicyState>({
    skipPageTrackingRecalculation: claimCreated,
  });
  const [startYourClaimState, setStartYourClaimState] = useSessionState<StartYourClaimState>({
    specificKey: FormRoute.StartYourClaim,
    skipPageTrackingRecalculation: true,
  });
  const [, setAboutTheAccidentState] = useSessionState<AboutTheAccidentState>({
    specificKey: FormRoute.AboutTheAccident,
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<YourPolicyFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: yourPolicyState,
  });
  const onSubmit: YourPolicyFormProps["onSubmit"] = async ({ policyNumber }) => {
    try {
      setBackdrop(true);

      const policyDetailsAlreadyFetched = startYourClaimState.policyDetails?.policyNumber === policyNumber;

      if (!claimCreated && !policyDetailsAlreadyFetched) {
        const policyDetails = (await apiClient.getPolicy(policyNumber)).result;
        setStartYourClaimState({ isCompleted: false, policyDetails });
        setAboutTheAccidentState({ isCompleted: false });
      }

      setYourPolicyState({ ...yourPolicyState, policyNumber, isCompleted: true });
      navigate(FormRoute.StartYourClaim);
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `GET /policy`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  let policyOptions = beforeYouStartState.policies?.map((policyDetails) => getPolicyOption(policyDetails)) ?? [];

  if (claimCreated) {
    policyOptions = policyOptions.filter((policy) => policy.policyNumber === yourPolicyState.policyNumber);
  }

  return {
    form,
    onSubmit,
    claimCreated,
    policyOptions,
  };
};

export default useYourPolicy;
