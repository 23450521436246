import { useMyRacLogin as useMyRacLoginReal } from "raci-react-library";

let useMyRacLogin = useMyRacLoginReal;

if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const { useMyRacLogin: useMyRacLoginNpe } = require("raci-react-library-npe");
  useMyRacLogin = useMyRacLoginNpe;
}

export default useMyRacLogin;
