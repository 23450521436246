import { useSessionStatePrefix } from "raci-react-library";
import { ReactNode, createContext, useState } from "react";
import { ErrorRoute, FormRoute, getRouteUrl } from "../../routing/routes.config";

type FlowCompletedRoute =
  | FormRoute.StartYourClaim
  | FormRoute.AboutTheAccident
  | FormRoute.ThirdPartyDetails
  | FormRoute.Confirmation
  | ErrorRoute;

export type FlowState = {
  isFlowCompleted: boolean;
  flowCompletedUrl: string | undefined;
};

export const FlowStateContext = createContext<
  [FlowState, setFlowCompleted: (route: FlowCompletedRoute) => void] | null
>(null);

export const FlowStateProvider = ({ children }: { children: ReactNode }) => {
  const sessionStatePrefix = useSessionStatePrefix();
  const flowStateStorageKey = `${sessionStatePrefix}FLOW_STATE`;
  const [flowState, setFlowState] = useState<FlowState>(() => {
    const flowState = sessionStorage.getItem(flowStateStorageKey);
    return flowState
      ? JSON.parse(flowState)
      : ({ isFlowCompleted: false, flowCompletedUrl: undefined } satisfies FlowState);
  });

  const setFlowCompleted = (route: FlowCompletedRoute) => {
    const flowCompletedState = { isFlowCompleted: true, flowCompletedUrl: getRouteUrl(route) } satisfies FlowState;
    setFlowState(flowCompletedState);
    sessionStorage.setItem(flowStateStorageKey, JSON.stringify(flowCompletedState));
  };

  return <FlowStateContext.Provider value={[flowState, setFlowCompleted]}>{children}</FlowStateContext.Provider>;
};

export default FlowStateProvider;
