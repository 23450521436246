import { ThirdPartyDetailsRequestIsThirdPartyKnownToPolicyHolder } from "raci-claims-motor-collision-clientproxy";
import { YesNo } from "raci-react-library";
import { FormQuestions } from "../../shared/types";
import { ThirdPartyDetailsFormValues } from "./types";

export const ThirdPartyDetailsQuestions = {
  hasOwnerDetails: {
    id: "has-owner-details",
    name: "hasOwnerDetails",
    label: "Do you have any of their details?",
  },
  thirdPartyCarRegistration: {
    id: "third-party-car-registration",
    name: "thirdPartyCarRegistration",
    label: "Car registration",
  },
  thirdPartyFirstName: {
    id: "third-party-first-name",
    name: "thirdPartyFirstName",
    label: "First name",
  },
  thirdPartyLastName: {
    id: "third-party-last-name",
    name: "thirdPartyLastName",
    label: "Last name",
  },
  thirdPartyContactNumber: {
    id: "third-party-contact-number",
    name: "thirdPartyContactNumber",
    label: "Contact number",
  },
  thirdPartyEmail: {
    id: "third-party-email",
    name: "thirdPartyEmail",
    label: "Email",
  },
  thirdPartyAddress: {
    id: "third-party-address",
    name: "thirdPartyAddress",
    label: "Address",
  },
  isThirdPartyKnownToPolicyHolder: {
    id: "is-third-party-known-to-policy-holder",
    name: "isThirdPartyKnownToPolicyHolder",
    label: "Did you know the other party before the accident?",
  },
} as const satisfies FormQuestions<ThirdPartyDetailsFormValues>;

export const thirdPartyDetailsPageTitle = ({
  isSingleVehicleCollisionClaim,
}: {
  isSingleVehicleCollisionClaim: boolean;
}) =>
  isSingleVehicleCollisionClaim ? "Owner of the property or pet you collided with" : "Other driver in the accident";

export const IsThirdPartyKnownToPolicyHolderYesNo = {
  [YesNo.Yes]: ThirdPartyDetailsRequestIsThirdPartyKnownToPolicyHolder.Yes,
  [YesNo.No]: ThirdPartyDetailsRequestIsThirdPartyKnownToPolicyHolder.No,
} as const satisfies Record<YesNo, ThirdPartyDetailsRequestIsThirdPartyKnownToPolicyHolder>;
