import { useGetPreviousPageSessionState, useGetSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RepairerOptionsState } from "../../../views/RepairerOptions/types";
import useFlowState from "../../components/FlowStateProvider/useFlowState";
import { ErrorRoutePaths, FormRoute, getRouteUrl } from "../routes.config";
import useRoutes from "../useRoutes";

export const RouteGuard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();
  const { isCompleted: isRepairerOptionCompleted } = useGetSessionState<RepairerOptionsState>(
    FormRoute.RepairerOptions,
  );
  const [{ flowCompletedUrl }] = useFlowState();
  const { isReviewClaimCompleted } = useRoutes();

  const currentLocation = location.pathname.toLowerCase();
  const bypass = ErrorRoutePaths.filter((item) => item.toLowerCase() === currentLocation).length > 0;

  /* Forces the user to a particular page, depending on their current flow */
  useEffect(() => {
    if (bypass) {
      return;
    }

    // If the user has completed their claim, and tries to transition to a previous page
    if (flowCompletedUrl && currentLocation !== flowCompletedUrl) {
      return navigate(flowCompletedUrl, { replace: true });
    }

    // If the user has completed reviewing their claim but has not yet completed the repairerOptions page, and tries to transition to a previous page
    if (
      isReviewClaimCompleted &&
      !isRepairerOptionCompleted &&
      currentLocation !== getRouteUrl(FormRoute.RepairerOptions)
    ) {
      return navigate(getRouteUrl(FormRoute.RepairerOptions), { replace: false });
    }

    // If the previous page has not been completed
    if (previousPageUrl && !isPreviousPageCompleted) {
      return navigate(previousPageUrl, { replace: true });
    }
  }, [
    bypass,
    currentLocation,
    flowCompletedUrl,
    navigate,
    isRepairerOptionCompleted,
    isReviewClaimCompleted,
    previousPageUrl,
    isPreviousPageCompleted,
  ]);

  return <></>;
};

export default RouteGuard;
