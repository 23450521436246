import { Button, Grid } from "@mui/material";
import { NotificationCard } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import WitnessDetailList from "./components/WitnessDetailList";
import { witnessDetailsPageTitle } from "./constants";
import { WitnessDetailsFormProps } from "./types";

export const WitnessDetailsForm = ({ form, onSubmit }: WitnessDetailsFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PageTitle id="header" title={witnessDetailsPageTitle} showClaimNumber />
          </Grid>
          <Grid item xs={12} sx={{ margin: "16px 0 8px 0" }}>
            <NotificationCard
              severity="warning"
              message={{
                title: "Witnesses we can't use",
                content: (
                  <ul style={{ margin: 0 }}>
                    <li>Anyone you knew before the event</li>
                    <li>The tow truck driver</li>
                  </ul>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <WitnessDetailList />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            id="submit"
            data-testid="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            sx={{ marginTop: "32px" }}
          >
            Next
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default WitnessDetailsForm;
