import { RacwaBreadcrumbDetails } from "@racwa/react-components";
import { PreFormRoute, getRouteUrl } from "../../../routing/routes.config";

const FRAGMENT = "#";

export enum Breadcrumb {
  MakeAClaim = "Make a claim",
  ProductTypeInsurance = "Car insurance",
  CarAccident = "Car accident",
  Claim = "Claim",
}

type BreadcrumbLink = RacwaBreadcrumbDetails["links"][number];

const breadcrumbs: Record<Breadcrumb, BreadcrumbLink> = {
  [Breadcrumb.MakeAClaim]: {
    name: Breadcrumb.MakeAClaim,
    key: Breadcrumb.MakeAClaim,
    href: process.env.REACT_APP_RAC_MAKE_A_CLAIM_PAGE ?? FRAGMENT,
  },
  [Breadcrumb.ProductTypeInsurance]: {
    name: Breadcrumb.ProductTypeInsurance,
    key: Breadcrumb.ProductTypeInsurance,
    href: process.env.REACT_APP_SPARK_TRIAGE_CAR_INSURANCE_URL ?? FRAGMENT,
  },
  [Breadcrumb.CarAccident]: {
    name: Breadcrumb.CarAccident,
    key: Breadcrumb.CarAccident,
    href: getRouteUrl(PreFormRoute.BeforeYouStart) ?? FRAGMENT,
  },
  [Breadcrumb.Claim]: {
    name: Breadcrumb.Claim,
    key: Breadcrumb.Claim,
    href: FRAGMENT,
  },
};

export const getBreadcrumbs = (isActiveFlow: boolean): RacwaBreadcrumbDetails => {
  const links = [breadcrumbs[Breadcrumb.MakeAClaim], breadcrumbs[Breadcrumb.ProductTypeInsurance]];

  let currentPage: RacwaBreadcrumbDetails["currentPage"];

  if (isActiveFlow) {
    links.push(breadcrumbs[Breadcrumb.CarAccident]);
    currentPage = breadcrumbs[Breadcrumb.Claim];
  } else {
    currentPage = breadcrumbs[Breadcrumb.CarAccident];
  }

  return {
    links,
    currentPage,
  };
};

export default getBreadcrumbs;
