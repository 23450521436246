import { faToolbox, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { RepairerDetails } from "raci-claims-motor-collision-clientproxy";
import {
  CardWithIcon,
  CardWithIconProps,
  HTTP_QUERY_STRING_CLAIM_NUMBER,
  PhoneNumberLink,
  PhoneNumberLinkProps,
} from "raci-react-library";
import { useEffect } from "react";
import { ConfirmationFormProps } from "../../types";
import DamageImagesNotificationCard from "../DamageImagesNotificationCard";

export type NextStepsCardProps = Pick<
  ConfirmationFormProps,
  "selectedRepairer" | "carIsDriveable" | "isUninsuredMotoristExtensionClaim"
> & { claimNumber: string };

export const NextStepsCard = (props: NextStepsCardProps) => {
  const { variant, icon, title, paragraphs } = getVariant(props);

  useEffect(() => {
    switch (variant) {
      case "repairerDetails":
        gtm(event("Repairer card shown"));
        break;
      case "submitReceipts":
        gtm(event("Submit receipts card shown"));
        break;
      default:
        break;
    }
  }, [variant]);

  return (
    <CardWithIcon
      icon={icon}
      iconSize="24px"
      iconStyle={{ paddingTop: "5px", color: colors.dieselDeep }}
      title={<Typography variant="h3">{title}</Typography>}
      paragraphs={paragraphs}
      spacing={3}
      data-testid="next-steps"
    />
  );
};

const getVariant = ({
  claimNumber,
  selectedRepairer,
  carIsDriveable,
  isUninsuredMotoristExtensionClaim,
}: NextStepsCardProps) => {
  if (selectedRepairer) {
    return selectedRepairer === "Get a quote" ? variants.getAQuote : variants.repairerDetails(selectedRepairer);
  }

  if (carIsDriveable || isUninsuredMotoristExtensionClaim) {
    return variants.whatsNext;
  }

  return variants.submitReceipts(claimNumber);
};

type Variant = "whatsNext" | "getAQuote" | "repairerDetails" | "submitReceipts";
type VariantProps = Pick<CardWithIconProps, "icon" | "title" | "paragraphs"> & { variant: Variant };

const whatsNext = "What's next";
const youllReceiveAnEmailShortly =
  "You'll receive an email with your next steps shortly. If we need to, we'll call you.";

const variants = {
  whatsNext: {
    variant: "whatsNext",
    icon: faUserAlt,
    title: whatsNext,
    paragraphs: [<Typography variant="body1">{youllReceiveAnEmailShortly}</Typography>],
  } as const,
  getAQuote: {
    variant: "getAQuote",
    icon: faToolbox,
    title: "Please get a repair quote",
    paragraphs: [
      <Typography variant="body1">The email will explain how to do this in more detail.</Typography>,
      <Typography variant="body1">
        We'll review your claim and will let you know if we need more information.
      </Typography>,
    ],
  } as const,
  repairerDetails: ({ repairerName, repairerPhoneNumber }: RepairerDetails) =>
    ({
      variant: "repairerDetails",
      icon: faToolbox,
      title: `Please call ${repairerName}`,
      paragraphs: [
        <Typography variant="body1">
          Book your car in on{" "}
          <PhoneNumberLink id="repairer-card-phone-number-link" phoneNumber={toPhoneNumber(repairerPhoneNumber)} />.
        </Typography>,
        <Typography variant="body1">
          We'll review your claim and will let you know if we need more information.
        </Typography>,
      ],
    }) as const,
  submitReceipts: (claimNumber: string) =>
    ({
      variant: "submitReceipts",
      icon: faUserAlt,
      title: whatsNext,
      paragraphs: [
        <Typography variant="body1">{youllReceiveAnEmailShortly}</Typography>,
        <Typography variant="body1">You can submit receipts for towing or taxis if you have them.</Typography>,
        <DamageImagesNotificationCard />,
        <Button
          id="submit-receipts-now"
          variant="contained"
          color="secondary"
          href={getUploadInvoiceUrl(claimNumber)}
          rel="noreferrer noopener"
          onClick={() => gtm(event("Submit receipts now"))}
          fullWidth
        >
          Submit receipts now
        </Button>,
      ],
    }) as const,
} as const satisfies {
  [V in Variant]-?: VariantProps | ((...args: any[]) => VariantProps);
};

const toPhoneNumber = (phoneNumber: string | undefined): PhoneNumberLinkProps["phoneNumber"] => {
  const numberWithoutSpaces = phoneNumber?.replaceAll(" ", "") ?? "";

  return {
    display: phoneNumber ?? "",
    displayWithoutSpaces: numberWithoutSpaces,
    href: `tel:${numberWithoutSpaces}`,
  };
};

const getUploadInvoiceUrl = (claimNumber: string) => {
  const url = `${process.env.REACT_APP_SPARK_CLAIMS_SERVICING_UPLOAD_INVOICE_URL}?${HTTP_QUERY_STRING_CLAIM_NUMBER}=${claimNumber}`;
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { BypassService } = require("raci-react-library-npe");
    return BypassService.recreateUrlFromSession(url);
  } else {
    return url;
  }
};

export default NextStepsCard;
