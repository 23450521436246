import { useLocation } from "react-router-dom";
import { RouteInformation } from "../routes.config";

const defaultTitle = process.env.REACT_APP_DEFAULT_SITE_TITLE || "";
const envPrefix = process.env.REACT_APP_ENVIRONMENT !== "PRD" ? `**${process.env.REACT_APP_ENVIRONMENT}** - ` : "";

const createDocumentTitle = (page: string) => {
  return `${envPrefix}Claims - Motor - Car accident - ${page}`;
};

const useDocumentTitle = (allRoutes: RouteInformation[]) => {
  const location = useLocation();
  const route = allRoutes.find((item) => item.path === location.pathname);
  const documentTitle = route?.name ? createDocumentTitle(route.name) : defaultTitle;

  return documentTitle;
};

export default useDocumentTitle;
