import { formatDateToTimeString } from "raci-react-library";
import { StartYourClaimQuestions } from "../../../../../StartYourClaim/constants";
import { StartYourClaimFormValues, StartYourClaimState } from "../../../../../StartYourClaim/types";
import { FormSummary } from "../../../../types";

type Args = Pick<StartYourClaimState, keyof StartYourClaimFormValues>;

export const summariseStartYourClaim = ({ date, time }: Args) =>
  ({
    date: {
      question: StartYourClaimQuestions.date.label,
      answer: date ? new Date(date).toLocaleDateString("en-AU") : "-",
    },
    time: {
      question: StartYourClaimQuestions.time.label,
      answer: time ? formatDateToTimeString(new Date(time)) : "-",
    },
  }) as const satisfies FormSummary<StartYourClaimFormValues>;

export default summariseStartYourClaim;
