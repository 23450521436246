import { WitnessDetailsForm } from "./Form";
import useWitnessDetails from "./hooks/useWitnessDetails";

export const pageName = "Witness details";

export const WitnessDetails = () => {
  const props = useWitnessDetails();

  return <WitnessDetailsForm {...props} />;
};

export default WitnessDetails;
