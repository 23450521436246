import { faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { colors } from "@racwa/styles";
import { CardWithIcon, currencyFormatter } from "raci-react-library";

export interface ExcessCardProps {
  excess?: number;
  isLiabilityOnlyClaim: boolean;
}

export const ExcessCard = ({ excess, isLiabilityOnlyClaim }: ExcessCardProps) => {
  const zeroExcess = excess === 0;

  const title = zeroExcess
    ? "You don't have to pay an excess"
    : excess
      ? `Your total excess is ${currencyFormatter(excess, { withDecimals: true, stripZeroCents: true })}`
      : "Your excess";

  const messages = [
    zeroExcess
      ? "Given the information you provided, you don't have to pay an excess for this claim."
      : excess
        ? "The email will have more information on the excess and how to pay it."
        : "The email will confirm your excess and how to pay it.",
  ];

  if (isLiabilityOnlyClaim && !zeroExcess) {
    messages.push("Please pay your excess as soon as you can.");
  }

  return (
    <CardWithIcon
      icon={faMoneyBill1Wave}
      iconSize="24px"
      iconStyle={{ paddingTop: "5px", color: colors.dieselDeep }}
      title={<Typography variant="h3">{title}</Typography>}
      paragraphs={messages.map((m) => (
        <Typography variant="body1">{m}</Typography>
      ))}
      spacing={3}
      data-testid="excess-card"
    />
  );
};

export default ExcessCard;
