import { useLocation } from "react-router-dom";
import { ErrorRoute } from "../../routing/routes.config";
import useNavigateToRoute from "../../routing/useNavigateToRoute";

export const useOnExpiry = () => {
  const navigate = useNavigateToRoute();
  const location = useLocation();

  const navigateToExpirationPage = () => {
    navigate(ErrorRoute.SessionTimeout, {
      state: { referrer: location.pathname },
      replace: true,
    });
  };

  return navigateToExpirationPage;
};

export default useOnExpiry;
