import { ClaimState } from "../../../../../../shared/hooks/useClaimState";
import { MoreAboutTheAccidentQuestions, directionOfTravelOptions } from "../../../../../MoreAboutTheAccident/constants";
import { getMultiVehicleCollisionScenarioRadioGroupItems } from "../../../../../MoreAboutTheAccident/scenarios";
import { MoreAboutTheAccidentFormValues, MoreAboutTheAccidentState } from "../../../../../MoreAboutTheAccident/types";
import { FormSummary } from "../../../../types";

type Args = Pick<ClaimState, "isLiabilityOnlyClaim"> &
  Pick<MoreAboutTheAccidentState, keyof MoreAboutTheAccidentFormValues>;

export const summariseMoreAboutTheAccident = ({
  isLiabilityOnlyClaim,
  directionOfTravel,
  forwardScenario,
  multiVehicleCollisionScenario,
}: Args) =>
  ({
    directionOfTravel: {
      question: MoreAboutTheAccidentQuestions.directionOfTravel.label,
      answer: directionOfTravelOptions.find((opt) => opt.value === directionOfTravel)?.label,
    },
    forwardScenario: forwardScenario
      ? {
          question: MoreAboutTheAccidentQuestions.forwardScenario.label,
          answer: forwardScenario,
        }
      : undefined,
    multiVehicleCollisionScenario: multiVehicleCollisionScenario
      ? {
          question: MoreAboutTheAccidentQuestions.multiVehicleCollisionScenario.label({
            directionOfTravel,
            forwardScenario,
          }),
          answer:
            directionOfTravel &&
            getMultiVehicleCollisionScenarioRadioGroupItems(
              isLiabilityOnlyClaim,
              directionOfTravel,
              forwardScenario,
            ).find((opt) => opt.value === multiVehicleCollisionScenario)?.label,
        }
      : undefined,
  }) as const satisfies FormSummary<MoreAboutTheAccidentFormValues>;

export default summariseMoreAboutTheAccident;
