import { gtm, virtualPageView } from "@racwa/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { allRoutes } from "../../routing/routes.config";
import useDocumentTitle from "../../routing/useDocumentTitle";
import useFlowState from "../FlowStateProvider/useFlowState";

export interface TrackPageChangesProps {
  /** List of pages that should not be tracked */
  untrackedPages?: string[];
}

export const TrackPageChanges: React.FC<TrackPageChangesProps> = ({ untrackedPages }) => {
  const location = useLocation();
  const currentPage = location.pathname.toLocaleLowerCase();
  const shouldTrackCurrentPage = !untrackedPages?.includes(currentPage);
  const documentTitle = useDocumentTitle(allRoutes);
  const [{ isFlowCompleted }] = useFlowState();

  useEffect(() => {
    if (shouldTrackCurrentPage && !isFlowCompleted) {
      gtm(virtualPageView({ url: currentPage, title: documentTitle }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return null;
};
