import { ClaimsMotorCollisionApiException, InsuranceCompany } from "raci-claims-motor-collision-clientproxy";
import { useHasSession, useIsLoggedInToMyRac, useSetBackdrop, type Suburb as RRLSuburb } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SESSION_KEY_PREFIX } from "../../constants";
import { ErrorRoute } from "../../routing/routes.config";
import useNavigateToRoute from "../../routing/useNavigateToRoute";
import { useBffApiClient } from "../useApiClient";

export const STORAGE_KEY_SUBURBS = `${SESSION_KEY_PREFIX}SUBURBS`;
export const STORAGE_KEY_INSURANCE_COMPANIES = `${SESSION_KEY_PREFIX}INSURANCE_COMPANIES`;

export const useReferenceData = () => {
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const isLoggedIn = useIsLoggedInToMyRac();
  const hasSession = useHasSession();
  const navigate = useNavigateToRoute();
  const location = useLocation();
  const [suburbs, setSuburbs] = useState(
    JSON.parse(sessionStorage.getItem(STORAGE_KEY_SUBURBS) ?? "[]") as RRLSuburb[],
  );
  const [insuranceCompanies, setInsuranceCompanies] = useState(
    JSON.parse(sessionStorage.getItem(STORAGE_KEY_INSURANCE_COMPANIES) ?? "[]") as Required<InsuranceCompany>[],
  );

  useEffect(() => {
    const alreadyFetchedSuburbs = suburbs.length !== 0;
    const alreadyFetchedInsuranceCompanies = insuranceCompanies.length !== 0;

    const loadSuburbs = async () => {
      if (!alreadyFetchedSuburbs && hasSession && isLoggedIn) {
        try {
          setBackdrop(true);
          const { result: suburbsResult } = await apiClient.getSuburbs();
          if (suburbsResult.suburbs) {
            const suburbsList = suburbsResult.suburbs.filter((suburb): suburb is RRLSuburb => !!suburb?.cityName);
            sessionStorage.setItem(STORAGE_KEY_SUBURBS, JSON.stringify(suburbsList));
            setSuburbs(suburbsList);
          }
        } catch (e) {
          handleError(e, "GET /reference-data/suburbs");
        } finally {
          setBackdrop(false);
        }
      }
    };

    const loadInsuranceCompanies = async () => {
      if (!alreadyFetchedInsuranceCompanies && hasSession && isLoggedIn) {
        try {
          setBackdrop(true);
          const { result: insuranceCompaniesResult } = await apiClient.getInsuranceCompanies();
          if (insuranceCompaniesResult.insuranceCompanies) {
            const companiesList = insuranceCompaniesResult.insuranceCompanies.filter(
              (company): company is Required<InsuranceCompany> => !!company?.description,
            );
            sessionStorage.setItem(STORAGE_KEY_INSURANCE_COMPANIES, JSON.stringify(companiesList));
            setInsuranceCompanies(companiesList);
          }
        } catch (e) {
          handleError(e, "GET /reference-data/insurance-companies");
        } finally {
          setBackdrop(false);
        }
      }
    };

    loadSuburbs();
    loadInsuranceCompanies();

    function handleError(e: unknown, requestPath: string) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: requestPath, status: error.status },
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession]);

  return {
    suburbs,
    insuranceCompanies,
  };
};
