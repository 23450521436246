import { Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { ValidateClaimCreationErrorResponseIneligibleReason } from "raci-claims-motor-collision-clientproxy";
import { InsurancePhoneNumber, NotificationCard, PhoneNumberLink } from "raci-react-library";
import { useEffect } from "react";
export interface IneligibleForClaimErrorProps {
  reason?: ValidateClaimCreationErrorResponseIneligibleReason;
  duplicateClaimNumber?: string;
}

export const IneligibleForClaimError = ({ reason, duplicateClaimNumber }: IneligibleForClaimErrorProps) => {
  useEffect(() => {
    if (reason === ValidateClaimCreationErrorResponseIneligibleReason.PolicyNotActive) {
      gtm(event("Unfortunately, your policy wasn't active on this date"));
    } else if (reason === ValidateClaimCreationErrorResponseIneligibleReason.DuplicateClaim) {
      gtm(event("This policy has an existing car insurance claim for this date"));
    } else if (reason === ValidateClaimCreationErrorResponseIneligibleReason.PolicyChangedSinceEventDate) {
      gtm(event("Sorry, you can't claim online - Not current asset/policy"));
    } else if (reason === ValidateClaimCreationErrorResponseIneligibleReason.PolicyDetailsIncomplete) {
      gtm(event("Sorry, you can't claim online - No rego"));
    }
  }, [reason]);

  switch (reason) {
    case ValidateClaimCreationErrorResponseIneligibleReason.PolicyNotActive:
      return (
        <NotificationCard
          severity="error"
          message={{
            title: "Unfortunately, your policy wasn't active on this date",
            content: (
              <Typography>
                If you'd like to discuss this or if you think there's been a mistake, call us on{" "}
                <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id="insurance-phone-number-link" />.
              </Typography>
            ),
          }}
        />
      );
    case ValidateClaimCreationErrorResponseIneligibleReason.DuplicateClaim:
      return (
        <NotificationCard
          severity="error"
          message={{
            title: "This policy has an existing car insurance claim for this date",
            content: (
              <Typography>
                To continue this claim, please call{" "}
                <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id="insurance-phone-number-link" /> and quote the
                previous <strong>claim number {duplicateClaimNumber}</strong>.
              </Typography>
            ),
          }}
        />
      );
    case ValidateClaimCreationErrorResponseIneligibleReason.PolicyNotCoveredForChildCover:
    case ValidateClaimCreationErrorResponseIneligibleReason.PolicyNotCoveredForParentCover:
      return (
        <NotificationCard
          severity="error"
          message={{
            title: "Sorry, you've hit a road block",
            content: (
              <Typography>
                Please call us on{" "}
                <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id="insurance-phone-number-link" /> to make a claim.
              </Typography>
            ),
          }}
        />
      );
    case ValidateClaimCreationErrorResponseIneligibleReason.PolicyChangedSinceEventDate:
    case ValidateClaimCreationErrorResponseIneligibleReason.PolicyDetailsIncomplete:
      return (
        <NotificationCard
          severity="error"
          message={{
            title: "Sorry, you can't claim online",
            content: (
              <>
                {reason === ValidateClaimCreationErrorResponseIneligibleReason.PolicyChangedSinceEventDate && (
                  <Typography paragraph>We're having trouble matching the details on your policy.</Typography>
                )}
                <Typography>
                  Please call us on{" "}
                  <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id="insurance-phone-number-link" /> so we can help
                  you with your claim.
                </Typography>
              </>
            ),
          }}
        />
      );
    default:
      return null;
  }
};

export default IneligibleForClaimError;
