import { useContext } from "react";
import { FlowStateContext } from "..";

export const useFlowState = () => {
  const flowStateContext = useContext(FlowStateContext);

  if (flowStateContext === null) {
    throw new Error("useFlowState must be used within a FlowStateProvider");
  }

  return flowStateContext;
};

export default useFlowState;
