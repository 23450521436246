import { YesNoImNotSure, useGetSessionState, useSessionState } from "raci-react-library";
import { useEffect } from "react";
import useFlowState from "../../../../shared/components/FlowStateProvider/useFlowState";
import useClaimState from "../../../../shared/hooks/useClaimState";
import { FormRoute } from "../../../../shared/routing/routes.config";
import { AboutYourCarState } from "../../../AboutYourCar/types";
import { MoreAboutTheAccidentState } from "../../../MoreAboutTheAccident/types";
import { ReviewYourClaimState } from "../../../ReviewYourClaim/types";
import { ConfirmationFormProps, ConfirmationState } from "../../types";

export const useConfirmation = (): ConfirmationFormProps => {
  const [, setFlowCompleted] = useFlowState();
  const { isLiabilityOnlyClaim } = useClaimState();
  const { claimNumber } = useGetSessionState<MoreAboutTheAccidentState>(FormRoute.MoreAboutTheAccident);
  const { carIsDriveable: carIsDriveableValue } = useGetSessionState<AboutYourCarState>(FormRoute.AboutYourCar);
  const { isUninsuredMotoristExtensionClaim, hasHireCarCover, informantFirstName } =
    useGetSessionState<ReviewYourClaimState>(FormRoute.ReviewYourClaim);
  const [confirmationState, setConfirmationState] = useSessionState<ConfirmationState>();

  useEffect(() => {
    setConfirmationState({ ...confirmationState, isCompleted: true });
    setFlowCompleted(FormRoute.Confirmation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { lodgementDetails, selectedRepairer } = confirmationState;

  const carIsDriveable = carIsDriveableValue === YesNoImNotSure.Yes;

  return {
    claimNumber: claimNumber ?? "",
    informantFirstName: informantFirstName ?? "",
    isLiabilityOnlyClaim: isLiabilityOnlyClaim && !isUninsuredMotoristExtensionClaim,
    isUninsuredMotoristExtensionClaim: !!isUninsuredMotoristExtensionClaim,
    carIsDriveable,
    selectedRepairer,
    excess: lodgementDetails?.excess,
    hasHireCarCover: !!hasHireCarCover,
  };
};

export default useConfirmation;
