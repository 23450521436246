import { useEffect } from "react";
import { allRoutes } from "../routes.config";
import usePageTitle from "../useDocumentTitle";

export const TitleGuard = () => {
  const pageTitle = usePageTitle(allRoutes);

  useEffect(() => {
    if (document) {
      document.title = pageTitle;
    }
  }, [pageTitle]);

  return <></>;
};
