import { Grid, Typography } from "@mui/material";
import { formatIdString } from "raci-react-library";
import { FormSummary } from "../../types";

export interface SummarySectionProps<T extends { [key: string]: unknown }> {
  formSummary: FormSummary<T>;
}

const SummarySection = <T extends { [key: string]: unknown }>({ formSummary }: SummarySectionProps<T>) => {
  const sectionContent = Object.values(formSummary)
    .flatMap((a) => a)
    .filter((a) => a !== undefined)
    .map((a) => ({
      label: a?.question ?? "",
      content: a?.answer ?? "-",
      customId: a?.customId,
    }));

  return (
    <Grid container direction={"column"} spacing={1}>
      {sectionContent.map((subSection, index) => (
        <Grid key={index} item>
          <Typography variant="body2" id={`${subSection.customId ?? formatIdString(subSection.label)}-label`}>
            <b>{subSection.label}</b>
          </Typography>
          <Typography
            variant="body2"
            id={`${subSection.customId ?? formatIdString(subSection.label)}-content`}
            sx={{ wordBreak: "break-all" }}
          >
            {subSection.content}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default SummarySection;
