import { ClaimsMotorCollisionApiException } from "raci-claims-motor-collision-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useClaimState from "../../../../shared/hooks/useClaimState";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { AboutYourCarState } from "../../../AboutYourCar/types";
import { WheresYourCarState } from "../../../WheresYourCar/types";
import { WitnessDetailsFormProps, WitnessDetailsFormValues, WitnessDetailsState } from "../../types";

export const useWitnessDetails = (): WitnessDetailsFormProps => {
  const navigate = useNavigateToRoute();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const { isLiabilityOnlyClaim } = useClaimState();
  const [witnessDetailsState, setWitnessDetailsState] = useSessionState<WitnessDetailsState>({
    skipPageTrackingRecalculation: true,
  });
  const [, setAboutYourCarState] = useSessionState<AboutYourCarState>({
    specificKey: FormRoute.AboutYourCar,
    skipPageTrackingRecalculation: true,
  });
  const [, setWheresYourCarState] = useSessionState<WheresYourCarState>({
    specificKey: FormRoute.WheresYourCar,
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<WitnessDetailsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: witnessDetailsState,
  });

  const onSubmit = async (values: WitnessDetailsFormValues) => {
    try {
      setBackdrop(true);

      if (answersHaveChanged({ newAnswers: values, oldAnswers: witnessDetailsState })) {
        await apiClient.witnessDetails(values);
      }

      setWitnessDetailsState({
        ...values,
        isCompleted: true,
      });

      if (isLiabilityOnlyClaim) {
        setAboutYourCarState({ isCompleted: true });
        setWheresYourCarState({ isCompleted: true });
        navigate(FormRoute.ReviewYourClaim);
      } else {
        navigate(FormRoute.AboutYourCar);
      }
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /step/witness-details`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
  };
};

const answersHaveChanged = ({
  newAnswers,
  oldAnswers,
}: {
  newAnswers: WitnessDetailsFormValues;
  oldAnswers: WitnessDetailsState;
}) => {
  if ((oldAnswers?.witnesses?.length ?? 0) !== (newAnswers?.witnesses?.length ?? 0)) {
    return true;
  }
  return JSON.stringify(newAnswers) !== JSON.stringify(oldAnswers);
};

export default useWitnessDetails;
