import { Button, Grid, Link } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { ClaimDetailsLockedCard, PolicyNotListedDialog, PolicyNumberPrefix, SelectionGroup } from "raci-react-library";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { YourPolicyFormProps } from "./types";

const policyNotListedLabel = "Car insurance policy not listed?";

export const YourPolicyForm = ({ form, policyOptions, claimCreated, onSubmit }: YourPolicyFormProps) => {
  const [showPolicyNotListedDialog, setShowPolicyNotListedDialog] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <PageTitle title="Which car was involved?" id="header" />
          </Grid>
          <Grid item xs>
            <SelectionGroup name="policyNumber" disabled={claimCreated} exclusive>
              <SelectionGroup.Column>
                {policyOptions.map(({ policyNumber, policyTitle, policySubtitle }) => (
                  <SelectionGroup.PolicyCard
                    key={policyNumber}
                    policyNumber={policyNumber}
                    policyTitle={policyTitle}
                    policySubtitle={policySubtitle}
                    onSelect={() => {
                      !policySubtitle && gtm(event("No car rego"));
                      gtm(fieldTouched("Which car was involved?"));
                    }}
                  />
                ))}
              </SelectionGroup.Column>
            </SelectionGroup>
          </Grid>
          <Grid item xs>
            {claimCreated ? (
              <ClaimDetailsLockedCard />
            ) : (
              <Link
                style={{ cursor: "pointer" }}
                variant="h4"
                onClick={() => {
                  setShowPolicyNotListedDialog(true);
                }}
              >
                {policyNotListedLabel}
              </Link>
            )}
          </Grid>
          <Grid item xs>
            <Button
              type="submit"
              id="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ marginTop: "20px", marginBottom: "16px" }}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <PolicyNotListedDialog
          policyNumberPrefix={PolicyNumberPrefix.Motor}
          showDialog={showPolicyNotListedDialog}
          setShowDialog={setShowPolicyNotListedDialog}
        />
      </form>
    </FormProvider>
  );
};

export default YourPolicyForm;
