import { LoaderOptions } from "@googlemaps/js-api-loader";
import { debounce } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete, RacwaAutocompleteInitialProps } from "@racwa/react-components";
import parse from "autosuggest-highlight/parse";
import * as React from "react";
import GoogleMarker from "../GoogleMarker";
import { defaultWACoordinates } from "../RacwaMaps";

export interface RacwaMapsSearchProps extends LoaderOptions, RacwaAutocompleteInitialProps {
  id: string;
  apiKey: string;
  sublabel: string;
  onLocationSelected?: (placeId: string) => void;
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

const RacwaMapsSearch = ({
  id,
  apiKey,
  onLocationSelected,
  placeholder,
  sublabel,
  ...additionalOptions
}: RacwaMapsSearchProps) => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(
          {
            input: request.input,
            locationBias: {
              center: defaultWACoordinates,
              radius: 2000,
            },
          },
          callback,
        );
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <RacwaAutocomplete
      id="google-map-demo"
      sublabel={sublabel}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      label={additionalOptions.label}
      placeholder={placeholder}
      autoComplete
      autoHighlight
      includeInputInList
      filterSelectedOptions
      freeSolo
      value={value}
      onChange={(event: any, newValue: any | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (onLocationSelected && newValue && newValue.place_id) {
          onLocationSelected(newValue.place_id);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onBlur={() => {
        gtm(fieldTouched("Search for a location to get started"));
      }}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <GoogleMarker sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box key={index} component="span" sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default RacwaMapsSearch;
