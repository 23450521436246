import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "@racwa/styles";
import { ClaimNumberDisplay } from "raci-react-library";
import CarTowedCard from "./components/CarTowedCard";
import DamageImagesNotificationCard from "./components/DamageImagesNotificationCard";
import ExcessCard from "./components/ExcessCard";
import HireCarCoverCard from "./components/HireCarCoverCard";
import NextStepsCard from "./components/NextStepsCard";
import { ConfirmationFormProps } from "./types";

export const ConfirmationForm = ({
  claimNumber,
  informantFirstName,
  isLiabilityOnlyClaim,
  isUninsuredMotoristExtensionClaim,
  carIsDriveable,
  selectedRepairer,
  excess,
  hasHireCarCover,
}: ConfirmationFormProps) => {
  return (
    <Grid container spacing={3} sx={{ justifyContent: "center", alignItems: "center" }}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", textAlign: "center" }}>
          <FontAwesomeIcon icon={faThumbsUp} size="7x" style={{ color: colors.dieselDeeper }} />
          <Typography
            id="confirmation-header-title"
            data-testid="confirmation-header-title"
            sx={{ fontWeight: "600" }}
            variant="h2"
          >
            Claim received - thanks, {informantFirstName}
          </Typography>
          {(selectedRepairer || isLiabilityOnlyClaim) && (
            <Typography
              id="confirmation-header-subtitle"
              data-testid="confirmation-header-subtitle"
              sx={{ fontWeight: "400", color: colors.dieselDeep }}
            >
              You'll receive an email shortly.
            </Typography>
          )}
          <ClaimNumberDisplay number={claimNumber} />
        </Box>
      </Grid>
      {!isLiabilityOnlyClaim && (
        <Grid item xs={12}>
          <NextStepsCard
            claimNumber={claimNumber}
            selectedRepairer={selectedRepairer}
            carIsDriveable={carIsDriveable}
            isUninsuredMotoristExtensionClaim={isUninsuredMotoristExtensionClaim}
          />
        </Grid>
      )}
      {isUninsuredMotoristExtensionClaim && (
        <Grid item xs={12}>
          <CarTowedCard />
        </Grid>
      )}
      <Grid item xs={12}>
        <ExcessCard excess={excess} isLiabilityOnlyClaim={isLiabilityOnlyClaim} />
      </Grid>
      {!isLiabilityOnlyClaim && !isUninsuredMotoristExtensionClaim && hasHireCarCover && (
        <Grid item xs={12}>
          <HireCarCoverCard />
        </Grid>
      )}
      {(carIsDriveable || isLiabilityOnlyClaim || isUninsuredMotoristExtensionClaim) && (
        <Grid item xs={12}>
          <DamageImagesNotificationCard />
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          type="button"
          role="button"
          fullWidth
          href={process.env.REACT_APP_RAC_HOME_PAGE}
          id="rac-home-page-link-button"
          data-testid="rac-home-page-link-button"
          rel="noopener noreferrer"
        >
          Go to RAC homepage
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmationForm;
