import { YesNoImNotSure, formatPhoneNumber } from "raci-react-library";
import { ClaimState } from "../../../../../../shared/hooks/useClaimState";
import { formatUndefinedToDash } from "../../../../../../shared/utils/helpers";
import { AboutYourCarState } from "../../../../../AboutYourCar/types";
import { CarLocation, WheresYourCarQuestions } from "../../../../../WheresYourCar/constants";
import { WheresYourCarFormValues, WheresYourCarState } from "../../../../../WheresYourCar/types";
import { FormSummary } from "../../../../types";

type Args = Pick<ClaimState, "isLiabilityOnlyClaim"> &
  Pick<AboutYourCarState, "carIsDriveable" | "carWasTowed"> &
  Pick<WheresYourCarState, keyof WheresYourCarFormValues>;

export const summariseWheresYourCar = ({
  isLiabilityOnlyClaim,
  carIsDriveable,
  carWasTowed,
  carLocation,
  businessName,
  businessContactNumber,
  carLocationDetails,
}: Args) => {
  if (isLiabilityOnlyClaim || carIsDriveable === YesNoImNotSure.Yes) {
    return undefined;
  }

  const isCarTowed = carWasTowed === YesNoImNotSure.Yes;
  const isCarAtHoldingYardOrRepairer =
    isCarTowed && (carLocation === CarLocation.TowTruckHoldingYard || carLocation === CarLocation.Repairer);
  const isCarAtYourHome = isCarTowed && carLocation === CarLocation.YourHome;

  return {
    carLocation: isCarTowed
      ? {
          question: WheresYourCarQuestions.carLocation.label,
          answer: formatUndefinedToDash(carLocation),
        }
      : undefined,
    businessName: isCarAtHoldingYardOrRepairer
      ? {
          question: WheresYourCarQuestions.businessName.label,
          answer: formatUndefinedToDash(businessName),
        }
      : undefined,
    businessContactNumber: isCarAtHoldingYardOrRepairer
      ? {
          question: WheresYourCarQuestions.businessContactNumber.label,
          answer: formatPhoneNumber(formatUndefinedToDash(businessContactNumber)),
          customId: "businessContactNumber",
        }
      : undefined,
    carLocationDetails: !isCarAtYourHome
      ? {
          question: WheresYourCarQuestions.carLocationDetails.label(carLocation),
          answer: formatUndefinedToDash(carLocationDetails),
        }
      : undefined,
  } as const satisfies FormSummary<WheresYourCarFormValues> | undefined;
};

export default summariseWheresYourCar;
