import { WheresYourCarRequestCarLocation } from "raci-claims-motor-collision-clientproxy";
import { FormQuestions } from "../../shared/types";
import { WheresYourCarFormValues } from "./types";

export const wheresYourCarPageTitle = "Where's your car";

export const WheresYourCarQuestions = {
  carLocation: {
    id: "car-location",
    label: "Your car is at...",
    name: "carLocation",
  },
  businessName: {
    id: "business-name",
    label: "Business name",
    name: "businessName",
  },
  businessContactNumber: {
    id: "business-contact-number",
    label: "Contact number",
    name: "businessContactNumber",
  },
  carLocationDetails: {
    id: "car-location-details",
    label: (carLocation?) => getCarLocationDetailsLabels(carLocation as CarLocationsExcludingHome),
    name: "carLocationDetails",
  },
} as const satisfies FormQuestions<WheresYourCarFormValues>;

export enum CarLocation {
  TowTruckHoldingYard = "A tow truck holding yard",
  Repairer = "A repairer",
  YourHome = "Your home",
  Other = "Other",
  IDontKnow = "I don't know",
}

export type CarLocationsExcludingHome = Exclude<CarLocation, CarLocation.YourHome>;

export const getCarLocationDetailsLabels = (carLocation?: CarLocationsExcludingHome): string => {
  switch (carLocation) {
    case CarLocation.TowTruckHoldingYard:
    case CarLocation.Repairer:
      return "Address or suburb";
    case CarLocation.Other:
      return "Please tell us where your car is";
    case CarLocation.IDontKnow:
      return "Please provide any details that may help us locate your car";
    default:
      return "Your car's location";
  }
};

export const CarLocationMap = {
  [CarLocation.TowTruckHoldingYard]: WheresYourCarRequestCarLocation.TowTruckHoldingYard,
  [CarLocation.Repairer]: WheresYourCarRequestCarLocation.Repairer,
  [CarLocation.YourHome]: WheresYourCarRequestCarLocation.Home,
  [CarLocation.Other]: WheresYourCarRequestCarLocation.Other,
  [CarLocation.IDontKnow]: WheresYourCarRequestCarLocation.DontKnow,
} as const satisfies Record<CarLocation, WheresYourCarRequestCarLocation>;
