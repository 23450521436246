import {
  ErrorContentPhoneButton,
  SystemUnavailableContent,
  trackSystemUnavailablePage,
  useClearSession,
  useGetSessionState,
} from "raci-react-library";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useFlowState from "../../shared/components/FlowStateProvider/useFlowState";
import { ErrorRoute, FormRoute, PreFormRoute, getRouteUrl } from "../../shared/routing/routes.config";
import { MoreAboutTheAccidentState } from "../MoreAboutTheAccident/types";

export const SystemUnavailable = () => {
  const location = useLocation();
  const clearSession = useClearSession();
  const [, setFlowCompleted] = useFlowState();
  const { claimNumber } = useGetSessionState<MoreAboutTheAccidentState>(FormRoute.MoreAboutTheAccident);
  const isMemberContactError = location.state?.isMemberContactError ?? false;

  useEffect(() => {
    setFlowCompleted(ErrorRoute.SystemUnavailable);

    trackSystemUnavailablePage(
      location.pathname.toLocaleLowerCase(),
      location.state?.referrer ?? document.referrer,
      location.state?.exception ?? null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMemberContactError ? (
    <SystemUnavailableContent
      subtitle={"We couldn't complete your request online. Please call us."}
      buttons={[ErrorContentPhoneButton]}
    />
  ) : (
    <SystemUnavailableContent
      variant="claims"
      claimNumber={claimNumber}
      startClaimAgainUrl={getRouteUrl(PreFormRoute.BeforeYouStart)}
      onStartClaimAgain={clearSession}
    />
  );
};

export default SystemUnavailable;
