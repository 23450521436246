import { formatPhoneNumber } from "raci-react-library";
import { ContactDetailsQuestions } from "../../../../../ContactDetails/constants";
import { ContactDetailsFormValues, ContactDetailsState } from "../../../../../ContactDetails/types";
import { FormSummary } from "../../../../types";

type Args = Pick<ContactDetailsState, keyof ContactDetailsFormValues>;

export const summariseContactDetails = ({ contactNumber, email }: Args) =>
  ({
    contactNumber: {
      question: ContactDetailsQuestions.contactNumber.label,
      answer: contactNumber && formatPhoneNumber(contactNumber),
    },
    email: {
      question: ContactDetailsQuestions.email.label,
      answer: email,
    },
  }) as const satisfies FormSummary<ContactDetailsFormValues>;

export default summariseContactDetails;
