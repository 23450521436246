import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { CardWithIcon, InsurancePhoneNumber, PhoneNumberLink } from "raci-react-library";
import { useEffect } from "react";

export const CarTowedCard = () => {
  useEffect(() => {
    gtm(event("Tow card shown"));
  }, []);

  return (
    <CardWithIcon
      data-testid="car-towed-card"
      icon={faWarning}
      iconSize="24px"
      iconStyle={{ paddingTop: "5px", color: colors.dieselDeep }}
      title={<Typography variant="h3">If your car was towed</Typography>}
      paragraphs={[
        <Typography variant="body1">
          It's important you call us on{" "}
          <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id="insurance-phone-number-link" /> as there may be costs
          that we don't cover.
        </Typography>,
      ]}
      spacing={3}
    />
  );
};

export default CarTowedCard;
