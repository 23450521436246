import { ClaimsMotorCollisionApiException, WheresYourCarRequest } from "raci-claims-motor-collision-clientproxy";
import {
  RadioButtonGroupItem,
  YesNoImNotSure,
  formatIdString,
  useGetSessionState,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { AboutYourCarState } from "../../../AboutYourCar/types";
import { MoreAboutTheAccidentState } from "../../../MoreAboutTheAccident/types";
import { CarLocation, CarLocationMap } from "../../constants";
import { WheresYourCarFormProps, WheresYourCarFormValues, WheresYourCarState } from "../../types";

export const useWheresYourCar = (): WheresYourCarFormProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const location = useLocation();
  const { riskAddress } = useGetSessionState<MoreAboutTheAccidentState>(FormRoute.MoreAboutTheAccident);
  const { carWasTowed } = useGetSessionState<AboutYourCarState>(FormRoute.AboutYourCar);
  const [wheresYourCarState, setWheresYourCarState] = useSessionState<WheresYourCarState>();

  const form = useForm<WheresYourCarFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: wheresYourCarState,
    shouldUnregister: true,
  });

  const carLocationOptions = getCarLocationOptions({ carTowed: carWasTowed === YesNoImNotSure.Yes, riskAddress });

  const onSubmit: WheresYourCarFormProps["onSubmit"] = async (newValues) => {
    try {
      setBackdrop(true);

      if (answersHaveChanged({ newAnswers: newValues, oldAnswers: wheresYourCarState })) {
        await apiClient.wheresYourCar(createRequest(newValues));
      }

      setWheresYourCarState({
        ...newValues,
        isCompleted: true,
      });
      navigate(FormRoute.ReviewYourClaim);
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `GET /step/wheres-your-car`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    carLocationOptions,
    onSubmit,
  };
};

export const getCarLocationOptions = ({
  carTowed,
  riskAddress,
}: {
  carTowed: boolean;
  riskAddress?: string;
}): RadioButtonGroupItem[] | undefined => {
  if (!carTowed) {
    return undefined;
  }

  const carLocations = Object.values(CarLocation).filter(
    (location) => location !== CarLocation.YourHome || riskAddress,
  );

  return carLocations.map((location) => ({
    id: formatIdString(location),
    value: location,
    label: location,
    sublabel: `${location === CarLocation.YourHome && riskAddress ? riskAddress : ""}`,
  }));
};

const answersHaveChanged = ({
  newAnswers,
  oldAnswers,
}: {
  newAnswers: WheresYourCarFormValues;
  oldAnswers: WheresYourCarState;
}) =>
  newAnswers.carLocation !== oldAnswers.carLocation ||
  newAnswers.businessName !== oldAnswers.businessName ||
  newAnswers.businessContactNumber !== oldAnswers.businessContactNumber ||
  newAnswers.carLocationDetails !== oldAnswers.carLocationDetails;

const createRequest = (formValues: WheresYourCarFormValues) =>
  ({
    ...formValues,
    carLocation: formValues.carLocation && CarLocationMap[formValues.carLocation],
  }) satisfies WheresYourCarRequest;

export default useWheresYourCar;
