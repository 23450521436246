import { ClaimsMotorCollisionApiException } from "raci-claims-motor-collision-clientproxy";
import { Address, useDebounce } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ErrorRoute } from "../../routing/routes.config";
import useNavigateToRoute from "../../routing/useNavigateToRoute";
import { useBffApiClient } from "../useApiClient";

export const useSearchAddress = (initialAddress?: Address) => {
  const apiClient = useBffApiClient();
  const navigate = useNavigateToRoute();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(initialAddress?.displayAddress ?? "");
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [address, setAddress] = useState<Address | null>(initialAddress || null);

  const minimumLengthToSearch = 10;

  const redirectToSystemUnavailable = (status: number) => {
    navigate(ErrorRoute.SystemUnavailable, {
      state: {
        referrer: location.pathname,
        exception: { request: "POST /claims/validate-creation", status: status },
      },
    });
  };

  const onAddressSelection = (selectedAddress: Address | null) => {
    setSearchTerm(selectedAddress?.displayAddress || "");
    setAddress(selectedAddress || null);
    setAddresses([]);
  };

  const onAddressInputChange = (value: string) => {
    if (!value) {
      setAddress(null);
      setAddresses([]);
    }

    if (addresses.some((a) => a.displayAddress?.toLocaleLowerCase().includes(value.toLocaleLowerCase())) === false) {
      setAddresses([]);
    }

    setSearchTerm(value);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isCancelled = false;

    if (debouncedSearchTerm) {
      if (debouncedSearchTerm.length >= minimumLengthToSearch && debouncedSearchTerm !== address?.displayAddress) {
        const fetchAddresses = async () => {
          setIsLoading(true);
          try {
            if (!isCancelled) {
              const response = await apiClient.getAddresses(debouncedSearchTerm);
              setAddresses(response.result.addresses ?? []);
            }
          } catch (exception) {
            const errorResponse = exception as ClaimsMotorCollisionApiException;

            if (errorResponse.status >= 500) {
              redirectToSystemUnavailable(errorResponse.status);
            }
            setAddresses([]);
          } finally {
            setIsLoading(false);
          }
        };

        fetchAddresses();
      }
    } else {
      setAddress(null);
      setAddresses([]);
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return {
    onAddressInputChange,
    onAddressSelection,
    addressResults: addresses,
    isLoading,
  };
};

export default useSearchAddress;
