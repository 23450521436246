import { formatPhoneNumber } from "raci-react-library";
import { WitnessDetailsFormValues, WitnessDetailsState } from "../../../../../WitnessDetails/types";
import { AnswerDetails, FormSummary } from "../../../../types";

type Args = Pick<WitnessDetailsState, keyof WitnessDetailsFormValues>;

export const summariseWitnessDetails = ({ witnesses }: Args) =>
  ({
    witnesses:
      witnesses?.map(
        (witness, index) =>
          ({
            question: `Witness ${index + 1}`,
            answer: `${witness.firstName}${witness.lastName ? " " + witness.lastName : ""}${witness.contactNumber ? ", " + formatPhoneNumber(witness.contactNumber) : ""}${witness.email ? ", " + witness.email : ""}`,
          }) as const satisfies AnswerDetails,
      ) ?? [],
  }) as const satisfies FormSummary<WitnessDetailsFormValues>;

export default summariseWitnessDetails;
