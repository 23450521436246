import { MoreThirdPartyDetailsRequestDriverWasTheOwner } from "raci-claims-motor-collision-clientproxy";
import { YesNoUnknown } from "raci-react-library";
import { FormQuestions } from "../../shared/types";
import { MoreThirdPartyDetailsFormValues } from "./types";

export const MoreThirdPartyDetailsQuestions = {
  isDriverTheOwner: {
    id: "is-driver-the-owner",
    name: "isDriverTheOwner",
    label: "Was the driver the owner?",
  },
  ownersNameAndContactDetails: {
    id: "owners-name-and-contact-details",
    name: "ownersNameAndContactDetails",
    label: "Owner's name and contact details",
  },
  ownersInsuranceCompany: {
    id: "owners-insurance-company",
    name: "ownersInsuranceCompany",
    label: ({ isSingleVehicleCollisionClaim }: { isSingleVehicleCollisionClaim: boolean }) =>
      isSingleVehicleCollisionClaim ? "Their property or pet insurance company" : "Their insurance company",
  },
  ownersClaimNumber: {
    id: "third-party-owner-claim-number",
    name: "ownersClaimNumber",
    label: "Their claim number",
  },
  damageDescription: {
    id: "damage-description",
    name: "damageDescription",
    label: ({ isSingleVehicleCollisionClaim }: { isSingleVehicleCollisionClaim: boolean }) =>
      isSingleVehicleCollisionClaim ? "Please describe their damage or injury" : "Please describe their damage",
  },
} as const satisfies FormQuestions<MoreThirdPartyDetailsFormValues>;

export const moreThirdPartyDetailsPageTitle = ({
  isSingleVehicleCollisionClaim,
}: {
  isSingleVehicleCollisionClaim: boolean;
}) => (isSingleVehicleCollisionClaim ? "More on the owner" : "Owner of the other vehicle");

type YesNoUnknownMap<T> = Record<YesNoUnknown, T>;

export const DriverWasTheOwnerYesNoUnknown = {
  [YesNoUnknown.Yes]: MoreThirdPartyDetailsRequestDriverWasTheOwner.Yes,
  [YesNoUnknown.No]: MoreThirdPartyDetailsRequestDriverWasTheOwner.No,
  [YesNoUnknown.Unknown]: MoreThirdPartyDetailsRequestDriverWasTheOwner.Unknown,
} as const satisfies YesNoUnknownMap<MoreThirdPartyDetailsRequestDriverWasTheOwner>;
