// <------------------>
//  Unicode Characters
// <------------------>
export const NON_BREAKING_SPACE = "\u00a0";

// <------------------>
//  URLs
// <------------------>
export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";

// <------------------>
//  Storage Keys
// <------------------>
export const SESSION_KEY_PREFIX = "RAC_CMC_";
export const STORAGE_KEY_POLICY_NUMBER = `${SESSION_KEY_PREFIX}POLICY_NUMBER`;
export const STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED = `${SESSION_KEY_PREFIX}_LOGIN_REGISTER_REDIRECT_TRIGGERED`;
export const STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW = `${SESSION_KEY_PREFIX}_HAS_ATTEMPTED_TO_ENTER_FLOW`;
export const STORAGE_KEY_TRACK_PAGE_VIEWS = `${SESSION_KEY_PREFIX}_TRACK_PAGE_VIEWS`;

// <------------------>
//  ADB2C
// <------------------>
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";
export const ADB2C_RETURN_URL = process.env.REACT_APP_ADB2C_RETURN_URL ?? "";
export const PRODUCT_LINKING_REDIRECT_URL = process.env.REACT_APP_PRODUCT_LINKING_REDIRECT_URL ?? "";
export const SILENT_LOGIN_REDIRECT_URL = `${process.env.PUBLIC_URL}/redirect`;

// <------------------>
//  Atoms
// <------------------>
export const ATOM_SESSION_ID = "AtomSessionId";

// <------------------>
//  Enums
// <------------------>
export enum BackdropMessage {
  ProcessingYourClaim = "Processing your claim\nPlease wait",
}

// <------------------>
// Maps
// <------------------>
export const GOOGLE_MAPS_API_KEY = 'AIzaSyB9dKvcSMGmMlUnMTk-FZ0brwV9DkaDQbY';

// <------------------>
// Toggle
// <------------------>
export const HIDE_LICENSE_QUESTION = process.env.REACT_APP_HIDE_LICENSE_QUESTION ?? false;
