import { ClaimsMotorCollisionApiException } from "raci-claims-motor-collision-clientproxy";
import {
  HTTP_STATUS_CODE_TIMEOUT,
  setIntervalGuarded,
  useSessionIdStorageKey,
  useSessionTimeRemaining,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ErrorRoute } from "../../routing/routes.config";
import useNavigateToRoute from "../../routing/useNavigateToRoute";
import { useBffApiClient } from "../useApiClient";

export const EXPIRY_CHECK_INTERVAL = 30000;
const getNextDelay = (expiry: number) => Math.max(expiry * 500, EXPIRY_CHECK_INTERVAL);

export const useSessionExpiry = () => {
  const { setTimeoutTime } = useSessionTimeRemaining();
  const location = useLocation();
  const navigate = useNavigateToRoute();
  const client = useBffApiClient();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const [wait, setWait] = useState(EXPIRY_CHECK_INTERVAL);

  useEffect(() => {
    let isCancelled = false;

    const checkSessionExpiry = async () => {
      try {
        const response = await client.getSessionExpiryInSeconds();
        if (!isCancelled) {
          const expiry = parseInt(response.result);
          setTimeoutTime(new Date().getTime() + expiry * 1000);
          setWait(getNextDelay(expiry));
        }
      } catch (ex) {
        const response = ex as ClaimsMotorCollisionApiException;
        if (response?.status === HTTP_STATUS_CODE_TIMEOUT) {
          setWait(getNextDelay(-1));
          navigate(ErrorRoute.SessionTimeout, {
            state: { referrer: location.pathname },
            replace: true,
          });
        }
      }
    };

    const isSessionTimeoutPage = location.pathname.endsWith(HTTP_STATUS_CODE_TIMEOUT.toString());

    if (!isSessionTimeoutPage) {
      const handle = setIntervalGuarded(async () => {
        if (sessionStorage.getItem(sessionIdStorageKey)) {
          await checkSessionExpiry();
        }
      }, wait);
      return () => {
        isCancelled = true;
        clearTimeout(handle);
      };
    }

    return () => {
      isCancelled = true;
    };
  }, [wait, client, navigate, location.pathname, sessionIdStorageKey, setTimeoutTime]);
};

export default useSessionExpiry;
