import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaAuth } from "@racwa/react-adb2c";
import { RacwaThemeProvider } from "@racwa/react-components";
import {
  Backdrop,
  CaptureQueryParameter,
  MyRacAuth,
  SessionStatePrefixProvider,
  SessionStatusProvider,
  SessionTimeoutDialog,
  SplashScreen,
  useSetSplashScreen,
  WebChat,
} from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import FlowStateProvider from "./shared/components/FlowStateProvider";
import { TrackPageChanges } from "./shared/components/TrackPageChanges";
import { SESSION_KEY_PREFIX, STORAGE_KEY_POLICY_NUMBER } from "./shared/constants";
import useCreateSession from "./shared/hooks/useCreateSession";
import useGetWebChatInformation from "./shared/hooks/useGetWebChatInformation";
import useOnExpiry from "./shared/hooks/useOnExpiry";
import useOnResetExpiry from "./shared/hooks/useOnResetExpiry";
import {
  ERROR_SESSION_TIMEOUT_PAGE_URL,
  ERROR_SYSTEM_UNAVAILABLE_PAGE_URL,
  ErrorRoutePaths,
  FORM_REPAIRER_OPTIONS_URL,
  FORM_REVIEW_YOUR_CLAIM_URL,
  PRE_FORM_BEFORE_YOU_START_URL,
  PreFormRoutePaths,
  UTILITY_REDIRECT_PAGE_URL,
} from "./shared/routing/routes.config";
import { reactPlugin } from "./shared/utils/applicationInsights";
import { getMyRacAuthProps, racwaAuthProps } from "./shared/utils/auth";
import Views from "./views";

export const AppInternal: React.FC = () => {
  const setSplashScreen = useSetSplashScreen();

  return (
    <RacwaAuth {...racwaAuthProps} onPasswordReset={() => setSplashScreen(true)}>
      <RacwaThemeProvider>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Backdrop />
          <SplashScreen />
          <BrowserRouter>
            <CaptureQueryParameter
              parameter="policyNumber"
              onCapture={(value) => sessionStorage.setItem(STORAGE_KEY_POLICY_NUMBER, value)}
            />
            <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
              <MyRacAuth {...getMyRacAuthProps()}>
                <FlowStateProvider>
                  <TrackPageChanges
                    untrackedPages={[
                      process.env.PUBLIC_URL,
                      ERROR_SYSTEM_UNAVAILABLE_PAGE_URL,
                      ERROR_SESSION_TIMEOUT_PAGE_URL,
                      UTILITY_REDIRECT_PAGE_URL,
                      PRE_FORM_BEFORE_YOU_START_URL,
                      FORM_REVIEW_YOUR_CLAIM_URL,
                      FORM_REPAIRER_OPTIONS_URL,
                    ]}
                  />
                  <SessionStatusProvider useCreateSession={useCreateSession}>
                    <Views />
                    <SessionTimeoutDialog
                      useOnResetExpiry={useOnResetExpiry}
                      useOnExpiry={useOnExpiry}
                      disallowPages={[...ErrorRoutePaths, ...PreFormRoutePaths]}
                    />
                    <WebChat useGetWebChatInformation={useGetWebChatInformation} />
                  </SessionStatusProvider>
                </FlowStateProvider>
              </MyRacAuth>
            </SessionStatePrefixProvider>
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </RacwaThemeProvider>
    </RacwaAuth>
  );
};

const App = () => (
  <RecoilRoot>
    <AppInternal />
  </RecoilRoot>
);

export default App;
