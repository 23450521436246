import { formatUndefinedToDash } from "../../../../../../shared/utils/helpers";
import { DriverOfYourCarQuestions, OtherDriverItem } from "../../../../../DriverOfYourCar/constants";
import { DriverOfYourCarFormValues, DriverOfYourCarState } from "../../../../../DriverOfYourCar/types";
import { MoreAboutTheAccidentState } from "../../../../../MoreAboutTheAccident/types";
import { FormSummary } from "../../../../types";

type Args = Pick<MoreAboutTheAccidentState, "additionalDrivers"> &
  Pick<DriverOfYourCarState, keyof DriverOfYourCarFormValues>;

export const summariseDriverOfYourCar = ({
  additionalDrivers,
  isDriver,
  policyDriver,
  otherDriverFirstName,
  otherDriverMiddleName,
  otherDriverLastName,
  otherDriverContactNumber,
  otherDriverDateOfBirth,
  otherDriverAddress,
}: Args) => {
  const hasPolicyDriver = additionalDrivers && additionalDrivers.length > 0;
  const isPolicyDriver = policyDriver !== OtherDriverItem.value;
  const isOtherDriver = !isDriver && (policyDriver === OtherDriverItem.value || !hasPolicyDriver);

  const additionalDriverDetailsAnswer = (additionalDrivers: MoreAboutTheAccidentState["additionalDrivers"]) => {
    const driver = additionalDrivers?.find((x) => x.id === policyDriver);
    return [driver?.firstName, driver?.middleName, driver?.lastName].filter(Boolean).join(" ");
  };

  return {
    isDriver: {
      question: DriverOfYourCarQuestions.isDriver.label,
      answer: isDriver ? "Yes" : "No",
    },
    policyDriver:
      !isDriver && hasPolicyDriver
        ? {
            question: DriverOfYourCarQuestions.policyDriver.label,
            answer: isPolicyDriver ? additionalDriverDetailsAnswer(additionalDrivers) : OtherDriverItem.label,
          }
        : undefined,
    otherDriverFirstName: isOtherDriver
      ? {
          question: DriverOfYourCarQuestions.otherDriverFirstName.label,
          answer: otherDriverFirstName,
          customId: DriverOfYourCarQuestions.otherDriverFirstName.id,
        }
      : undefined,
    otherDriverMiddleName: isOtherDriver
      ? {
          question: DriverOfYourCarQuestions.otherDriverMiddleName.label,
          answer: formatUndefinedToDash(otherDriverMiddleName),
        }
      : undefined,
    otherDriverLastName: isOtherDriver
      ? {
          question: DriverOfYourCarQuestions.otherDriverLastName.label,
          answer: otherDriverLastName,
          customId: DriverOfYourCarQuestions.otherDriverLastName.id,
        }
      : undefined,
    otherDriverDateOfBirth: isOtherDriver
      ? {
          question: DriverOfYourCarQuestions.otherDriverDateOfBirth.label,
          answer: otherDriverDateOfBirth && new Date(otherDriverDateOfBirth).toLocaleDateString("en-AU"),
          customId: DriverOfYourCarQuestions.otherDriverDateOfBirth.id,
        }
      : undefined,
    otherDriverContactNumber: isOtherDriver
      ? {
          question: DriverOfYourCarQuestions.otherDriverContactNumber.label,
          answer: formatUndefinedToDash(otherDriverContactNumber),
          customId: DriverOfYourCarQuestions.otherDriverContactNumber.id,
        }
      : undefined,
    otherDriverAddress: isOtherDriver
      ? {
          question: DriverOfYourCarQuestions.otherDriverAddress.label,
          answer: formatUndefinedToDash(otherDriverAddress?.displayAddress),
          customId: DriverOfYourCarQuestions.otherDriverAddress.id,
        }
      : undefined,
  } as const satisfies FormSummary<DriverOfYourCarFormValues>;
};

export default summariseDriverOfYourCar;
