import { Button, Divider, Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaEmailInput, RacwaFirstNameInput, RacwaLastNameInput } from "@racwa/react-components";
import {
  AddressInput,
  ContactNumberInput,
  EmailSizeLimit,
  EmailValidationPattern,
  FirstNameSizeLimit,
  FreeTextInput,
  InsurancePhoneNumberLink,
  LastNameSizeLimit,
  NameValidationPattern,
  NotificationCard,
  OptionalText,
  ToggleButtonGroup,
  YesNo,
  pleaseEnterAValidMessage,
} from "raci-react-library";
import { useEffect } from "react";
import { FormProvider, useController, useWatch } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { ThirdPartyDetailsQuestions, thirdPartyDetailsPageTitle } from "./constants";
import { ThirdPartyDetailsFormProps, ThirdPartyDetailsFormValues } from "./types";

const {
  hasOwnerDetails,
  thirdPartyAddress,
  thirdPartyCarRegistration,
  thirdPartyContactNumber,
  isThirdPartyKnownToPolicyHolder,
  thirdPartyEmail,
  thirdPartyFirstName,
  thirdPartyLastName,
} = ThirdPartyDetailsQuestions;

const nextButtonId = "submit-button";

export const ThirdPartyDetailsForm = ({
  form,
  addressInputProps: { addressResults, onAddressSelection, onAddressInputChange, isLoading: addressInputIsLoading },
  isLiabilityOnlyClaim,
  isSingleVehicleCollisionClaim,
  youCantClaimOnline,
  onSubmit,
}: ThirdPartyDetailsFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const hasOwnerDetailsValue = useWatch({ control, name: hasOwnerDetails.name });

  const toggleOptionsErrorMessage = "Please select Yes or No";

  const hasOwnerDetailsQuestionSubLabel = isSingleVehicleCollisionClaim
    ? "e.g. name, contact or insurance."
    : "e.g. name, contact, insurance or registration.";
  const EmailErrorMessage = pleaseEnterAValidMessage("email");

  const isNotLiabilityAndNoOwnerDetails = !isLiabilityOnlyClaim && hasOwnerDetailsValue === YesNo.No;
  const hasThirdPartyOwnersDetails = hasOwnerDetailsValue === YesNo.Yes;

  const { field: thirdPartyFirstNameFieldProps, fieldState: thirdPartyFirstNameFieldState } = useController<
    ThirdPartyDetailsFormValues,
    "thirdPartyFirstName"
  >({
    control,
    name: "thirdPartyFirstName",
    rules: {
      pattern: {
        value: NameValidationPattern,
        message: pleaseEnterAValidMessage("first name"),
      },
    },
  });

  const { field: thirdPartyLastNameFieldProps, fieldState: thirdPartyLastNameFieldState } = useController<
    ThirdPartyDetailsFormValues,
    "thirdPartyLastName"
  >({
    control,
    name: "thirdPartyLastName",
    rules: {
      pattern: {
        value: NameValidationPattern,
        message: pleaseEnterAValidMessage("last name"),
      },
    },
  });

  const { field: thirdPartyEmailFieldProps, fieldState: thirdPartyEmailFieldState } = useController<
    ThirdPartyDetailsFormValues,
    "thirdPartyEmail"
  >({
    control,
    name: "thirdPartyEmail",
    rules: {
      maxLength: {
        value: EmailSizeLimit,
        message: EmailErrorMessage,
      },
      pattern: {
        value: EmailValidationPattern,
        message: EmailErrorMessage,
      },
    },
  });

  useEffect(() => {
    if (youCantClaimOnline) {
      gtm(event("Sorry, you can't claim online"));
    }
  }, [youCantClaimOnline]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle
              id="third-party-details-header"
              title={thirdPartyDetailsPageTitle({ isSingleVehicleCollisionClaim })}
              showClaimNumber
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              id={hasOwnerDetails.id}
              name={hasOwnerDetails.name}
              label={hasOwnerDetails.label}
              sublabel={hasOwnerDetailsQuestionSubLabel}
              options={YesNo}
              errorMessage={toggleOptionsErrorMessage}
              disabled={youCantClaimOnline}
              onChange={(_, v) => {
                gtm(fieldTouched(hasOwnerDetails.label));
                gtm(event(`${hasOwnerDetails.label} - ${v}`));
              }}
            />
          </Grid>
          {isNotLiabilityAndNoOwnerDetails && (
            <Grid item xs={12} sx={{ margin: "8px 0 8px 0" }}>
              <NotificationCard
                severity="info"
                message={{
                  title: "Email us if you get their details later",
                }}
              />
            </Grid>
          )}
          {youCantClaimOnline && (
            <Grid item xs={12} sx={{ margin: "8px 0 8px 0" }}>
              <NotificationCard
                severity="error"
                message={{
                  title: "Sorry, you can't claim online",
                  content: (
                    <Grid>
                      <Typography paragraph>
                        Please call us on <InsurancePhoneNumberLink id="insurance-phone-number-link" /> so we can help
                        you with your claim.
                      </Typography>
                      <Typography>To speed up the process, please have their details ready before you call.</Typography>
                    </Grid>
                  ),
                }}
              />
            </Grid>
          )}
          {hasThirdPartyOwnersDetails && (
            <>
              <Grid item xs={12} sx={{ margin: "8px 0 8px 0" }}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{ margin: "8px 0 8px 0" }}>
                <Typography variant="h2">Fill in their details</Typography>
              </Grid>
              <Grid item xs={12} sx={{ margin: "8px 0 8px 0" }}>
                <NotificationCard
                  severity="info"
                  message={{
                    title: "Provide as much detail as you can",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {!isSingleVehicleCollisionClaim && (
                  <Grid item xs={12}>
                    <FreeTextInput
                      id={thirdPartyCarRegistration.id}
                      name={thirdPartyCarRegistration.name}
                      label={thirdPartyCarRegistration.label}
                      optionalText={OptionalText.IfKnown}
                      placeholder="e.g. RAC123"
                      fullWidth
                      multiline={false}
                      rows={1}
                      maxLength={12}
                      required={false}
                      validationMessage={pleaseEnterAValidMessage("car registration")}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <RacwaFirstNameInput
                    {...thirdPartyFirstNameFieldProps}
                    id={thirdPartyFirstName.id}
                    inputProps={{ maxLength: FirstNameSizeLimit }}
                    name={thirdPartyFirstName.name}
                    label={thirdPartyFirstName.label}
                    placeholder="e.g. John"
                    fullWidth
                    error={!!thirdPartyFirstNameFieldState.error}
                    optional
                    optionalText={OptionalText.IfKnown}
                    helperText={thirdPartyFirstNameFieldState.error?.message}
                    onBlur={(_) => gtm(fieldTouched(thirdPartyFirstName.label))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RacwaLastNameInput
                    {...thirdPartyLastNameFieldProps}
                    id={thirdPartyLastName.id}
                    inputProps={{ maxLength: LastNameSizeLimit }}
                    name={thirdPartyLastName.name}
                    label={thirdPartyLastName.label}
                    placeholder="e.g. Smith"
                    fullWidth
                    error={!!thirdPartyLastNameFieldState.error}
                    optional
                    optionalText={OptionalText.IfKnown}
                    helperText={thirdPartyLastNameFieldState.error?.message}
                    onBlur={(_) => gtm(fieldTouched(thirdPartyLastName.label))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ContactNumberInput
                    id={thirdPartyContactNumber.id}
                    name={thirdPartyContactNumber.name}
                    label={thirdPartyContactNumber.label}
                    required={false}
                    optional
                    optionalText={OptionalText.IfKnown}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RacwaEmailInput
                    {...thirdPartyEmailFieldProps}
                    id={thirdPartyEmail.id}
                    inputProps={{ maxLength: EmailSizeLimit }}
                    name={thirdPartyEmail.name}
                    label={thirdPartyEmail.label}
                    fullWidth
                    error={!!thirdPartyEmailFieldState.error}
                    optional
                    optionalText={OptionalText.IfKnown}
                    helperText={thirdPartyEmailFieldState.error?.message}
                    onBlur={(_) => gtm(fieldTouched(thirdPartyEmail.label))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddressInput
                    name={thirdPartyAddress.name}
                    label={thirdPartyAddress.label}
                    required={false}
                    data={addressResults}
                    onSelection={onAddressSelection}
                    onInputChange={onAddressInputChange}
                    optionalText={OptionalText.IfKnown}
                    isLoading={addressInputIsLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    id={isThirdPartyKnownToPolicyHolder.id}
                    name={isThirdPartyKnownToPolicyHolder.name}
                    label={isThirdPartyKnownToPolicyHolder.label}
                    options={YesNo}
                    errorMessage={toggleOptionsErrorMessage}
                    onChange={(_, v) => {
                      gtm(fieldTouched(isThirdPartyKnownToPolicyHolder.label));
                      gtm(event(`${isThirdPartyKnownToPolicyHolder.label} - ${v}`));
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              id={nextButtonId}
              data-testid={nextButtonId}
              color="primary"
              variant="contained"
              fullWidth
              sx={{ margin: "28px 0px 16px 0px" }}
              disabled={isSubmitting || youCantClaimOnline}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ThirdPartyDetailsForm;
