import { CommonPageProps } from "..";
import { ADB2C_CLIENT_ID, ADB2C_RETURN_URL } from "../../../constants";

export const getPageTemplateBannerAndButtons = (): Pick<
  CommonPageProps,
  "headerEndAction" | "notificationBannerContent"
> => {
  let headerEndAction = <></>;
  let notificationBannerContent;

  /*
   ** WARNING:
   ** KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO TO THE BELOW IF STATEMENT
   ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET ENVIRONMENT
   ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE AND SHIP CODE
   ** (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED JAVASCRIPT ARTIFACTS)
   */
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { HeaderButtons, LogoutButton, TestBanner } = require("raci-react-library-npe");
    const { EnvironmentHealthStatus } = require("../../../../__NPE__/EnvironmentHealthStatus");

    const npeButtons = [<EnvironmentHealthStatus />];

    if (process.env.REACT_APP_ENVIRONMENT !== "UAT") {
      npeButtons.push(
        <LogoutButton
          clientId={ADB2C_CLIENT_ID}
          postLogoutRedirectUri={ADB2C_RETURN_URL}
          testLaunchPagePostLogoutRedirectUri={process.env.REACT_APP_SPARK_TEST_LAUNCH_PAGE_URL ?? ""}
        />,
      );
    }

    notificationBannerContent = <TestBanner />;

    headerEndAction = <HeaderButtons npeButtons={npeButtons} isUat={process.env.REACT_APP_ENVIRONMENT === "UAT"} />;
  }

  return {
    headerEndAction,
    notificationBannerContent,
  };
};

export default getPageTemplateBannerAndButtons;
