import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export interface LogCustomProperties {
  location: string;
  attempts: string;
  timeTakenInSeconds?: string;
}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_INSTRUMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_INSIGHTS_URL}`,
    extensions: [reactPlugin as any],
    disableAjaxTracking: true,
    disableFetchTracking: true,
    disableExceptionTracking: true,
  },
});

// If you need to test application insights locally
// set the connection string in the .env file
// it's undefined by default since it's loaded by the pipeline
if (process.env.REACT_APP_INSTRUMENTATION_KEY !== undefined && process.env.REACT_APP_INSIGHTS_URL !== undefined) {
  appInsights.loadAppInsights();
}

export { reactPlugin };
