import { NavigateOptions, useNavigate } from "react-router-dom";
import { AppRoute, getRouteUrl } from "../routes.config";

export const useNavigateToRoute = () => {
  const navigate = useNavigate();

  return (route: AppRoute, options?: NavigateOptions) => {
    const routeUrl = getRouteUrl(route);
    options ? navigate(routeUrl, options) : navigate(routeUrl);
  };
};

export default useNavigateToRoute;
