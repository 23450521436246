import { WhereAndHowQuestions } from "../../../../../WhereAndHow/constants";
import { WhereAndHowFormValues, WhereAndHowState } from "../../../../../WhereAndHow/types";
import { FormSummary } from "../../../../types";

type Args = Omit<Pick<WhereAndHowState, keyof WhereAndHowFormValues | "mapDataImage">, "whereTheIncidentHappenedMap">;

export const summariseWhereAndHow = ({
  provideTheLocation,
  whereTheIncidentHappenedDescription,
  mapDataImage,
  howTheIncidentHappened,
  werePoliceInvolved,
  policeReportNumber,
}: Args) =>
  ({
    provideTheLocation: {
      question: WhereAndHowQuestions.provideTheLocation.label,
      answer: provideTheLocation,
    },
    whereTheIncidentHappenedDescription: {
      question: WhereAndHowQuestions.whereTheIncidentHappenedDescription.label,
      answer: whereTheIncidentHappenedDescription,
    },
    whereTheIncidentHappenedMap: {
      question: WhereAndHowQuestions.whereTheIncidentHappenedMap.label,
      answer: mapDataImage,
    },
    howTheIncidentHappened: {
      question: WhereAndHowQuestions.howTheIncidentHappened.label,
      answer: howTheIncidentHappened,
    },
    werePoliceInvolved: {
      question: WhereAndHowQuestions.werePoliceInvolved.label,
      answer: werePoliceInvolved,
    },
    policeReportNumber: policeReportNumber
      ? {
          question: WhereAndHowQuestions.policeReportNumber.label,
          answer: policeReportNumber,
        }
      : undefined,
  }) as const satisfies FormSummary<WhereAndHowFormValues>;

export default summariseWhereAndHow;
