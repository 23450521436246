import {
  DriverHistoryRequestDriverHasLicenceSuspensionOrCancellation,
  DriverHistoryRequestDriverHasValidLicence,
  DriverHistoryRequestDriverWasUnderTheInfluence,
} from "raci-claims-motor-collision-clientproxy";
import { YesNoUnknown } from "raci-react-library";
import { FormQuestions } from "../../shared/types";
import { DriverHistoryFormValues } from "./types";

export const UnderTheInfluenceGtmDescription = "Under the influence of drugs or alcohol at the time of the incident";
export const HeldValidLicenceGtmDescription = "Held a driver's licence for more than 2 years";
export const LicenceSuspendedGtmDescription = "Driver's licence been suspended or cancelled in the last 3 years";

export const DriverHistoryQuestions = {
  driverWasUnderTheInfluence: {
    id: "driver-was-under-the-influence",
    name: "driverWasUnderTheInfluence",
    label: ({ driverName }: { driverName: string | undefined }) =>
      `${
        driverName ? `Was ${driverName}` : "Were you"
      } under the influence of alcohol or drugs at the time of the incident?`,
  },
  driverHasValidLicence: {
    id: "driver-has-valid-licence",
    name: "driverHasValidLicence",
    label: ({ driverName }: { driverName: string | undefined }) =>
      `${driverName ? `Has ${driverName}` : "Have you"} held a driver's licence for more than 2 years?`,
  },
  driverHasLicenceSuspensionOrCancellation: {
    id: "driver-has-licence-suspension-or-cancellation",
    name: "driverHasLicenceSuspensionOrCancellation",
    label: ({ driverName }: { driverName: string | undefined }) =>
      `Has ${
        driverName ? `${driverName}'s` : "your"
      } driver's licence been suspended or cancelled in the last 3 years?`,
  },
  suspensionOrCancellationDetails: {
    id: "suspension-or-cancellation-details",
    name: "suspensionOrCancellationDetails",
    label: "Please provide details",
    subLabel: "Include dates, points lost, fines and blood alcohol level.",
  },
} as const satisfies FormQuestions<DriverHistoryFormValues>;

export const driverHistoryPageTitle = ({ driverName }: { driverName: string | undefined }) =>
  `${driverName ? `${driverName}'s` : "Your"} driver history` as const;

// TODO: generate client proxy with single YesNoUnknown enum
// when this azure-functions-openapi-extension fix is released
// https://github.com/Azure/azure-functions-openapi-extension/issues/153
type YesNoUnknownMap<T> = Record<YesNoUnknown, T>;

export const DriverWasUnderTheInfluenceYesNoUnknown = {
  [YesNoUnknown.Yes]: DriverHistoryRequestDriverWasUnderTheInfluence.Yes,
  [YesNoUnknown.No]: DriverHistoryRequestDriverWasUnderTheInfluence.No,
  [YesNoUnknown.Unknown]: DriverHistoryRequestDriverWasUnderTheInfluence.Unknown,
} as const satisfies YesNoUnknownMap<DriverHistoryRequestDriverWasUnderTheInfluence>;

export const DriverHasValidLicenceYesNoUnknown = {
  [YesNoUnknown.Yes]: DriverHistoryRequestDriverHasValidLicence.Yes,
  [YesNoUnknown.No]: DriverHistoryRequestDriverHasValidLicence.No,
  [YesNoUnknown.Unknown]: DriverHistoryRequestDriverHasValidLicence.Unknown,
} as const satisfies YesNoUnknownMap<DriverHistoryRequestDriverHasValidLicence>;

export const DriverHasLicenceSuspensionOrCancellationYesNoUnknown = {
  [YesNoUnknown.Yes]: DriverHistoryRequestDriverHasLicenceSuspensionOrCancellation.Yes,
  [YesNoUnknown.No]: DriverHistoryRequestDriverHasLicenceSuspensionOrCancellation.No,
  [YesNoUnknown.Unknown]: DriverHistoryRequestDriverHasLicenceSuspensionOrCancellation.Unknown,
} as const satisfies YesNoUnknownMap<DriverHistoryRequestDriverHasLicenceSuspensionOrCancellation>;
