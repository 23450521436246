import { SessionState, YesNoImNotSure } from "raci-react-library";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { Marker } from "../../shared/components/RacwaMaps";
export interface WhereAndHowFormProps {
  form: UseFormReturn<WhereAndHowFormValues>;
  googleMapsApiKey: string;
  onSubmit: SubmitHandler<WhereAndHowFormValues>;
}

export interface WhereAndHowFormValues {
  provideTheLocation: ProvideTheLocation;
  whereTheIncidentHappenedMap?: Marker;
  whereTheIncidentHappenedDescription?: string;
  howTheIncidentHappened: string;
  werePoliceInvolved: YesNoImNotSure;
  policeReportNumber?: string;
}

export enum ProvideTheLocation {
  UseAMap = "Use a map",
  DescribeLocation = "Describe location",
}

export type WhereAndHowState = SessionState<
  WhereAndHowFormValues & {
    mapDataImage?: string;
  }
>;
