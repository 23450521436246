import { Bollard, CarGarage, KangarooGRFX169, Other, PetGRFX206 } from "@racwa/react-components";
import { CreateClaimRequestCollisionScenario } from "raci-claims-motor-collision-clientproxy";
import { CardChoiceOption } from "raci-react-library";
import { FormQuestions, SingleVehicleCollisionScenario } from "../../shared/types";
import { AboutTheAccidentFormValues } from "./types";

export const aboutTheAccidentPageTitle = "About the accident";

export const AboutTheAccidentQuestions = {
  otherVehiclesInvolved: {
    id: "other-vehicles-involved",
    name: "otherVehiclesInvolved",
    label: "Number of other vehicles involved",
    subLabel: "Includes bicycles, scooters and trailers.",
  },
  singleVehicleCollisionScenario: {
    id: "single-vehicle-collision-scenario",
    name: "singleVehicleCollisionScenario",
    label: "The accident was with...",
  },
  propertyDamaged: {
    id: "property-damaged",
    name: "propertyDamaged",
    label: ({
      singleVehicleCollisionScenario,
    }: {
      singleVehicleCollisionScenario?: SingleVehicleCollisionScenario;
    }) => {
      switch (singleVehicleCollisionScenario) {
        case CreateClaimRequestCollisionScenario.HitSomeoneElsesProperty:
          return "Was their property damaged?";
        case CreateClaimRequestCollisionScenario.HitAnotherPersonsPetOrAnimal:
          return "Was the pet or animal injured?";
        case CreateClaimRequestCollisionScenario.SomethingElseHappened:
          return "Was someone else's property damaged?";
        default:
          return "";
      }
    },
  },
  claimForDamageToOwnCar: {
    id: "claim-for-damage-to-own-car",
    name: "claimForDamageToOwnCar",
    label: "Do you want to claim for the damage to your car?",
  },
} as const satisfies FormQuestions<AboutTheAccidentFormValues>;

export enum OtherVehiclesInvolved {
  None = "None, no other vehicles involved",
  ImNotSure = "I'm not sure",
  One = "1 other vehicle",
  Two = "2 other vehicles",
  ThreeOrMore = "3 or more other vehicles",
}

export const getOtherVehiclesInvolvedOptions = (hasComprehensiveCover: boolean): OtherVehiclesInvolved[] =>
  hasComprehensiveCover
    ? [
        OtherVehiclesInvolved.One,
        OtherVehiclesInvolved.Two,
        OtherVehiclesInvolved.ThreeOrMore,
        OtherVehiclesInvolved.None,
        OtherVehiclesInvolved.ImNotSure,
      ]
    : [
        OtherVehiclesInvolved.One,
        OtherVehiclesInvolved.Two,
        OtherVehiclesInvolved.ThreeOrMore,
        OtherVehiclesInvolved.None,
      ];

export const singleVehicleCollisionScenarioOptions = [
  {
    key: CreateClaimRequestCollisionScenario.HitYourOwnProperty,
    value: CreateClaimRequestCollisionScenario.HitYourOwnProperty,
    label: "Your own property",
    sublabel: "e.g. garage door, fence, wall, pillar",
    icon: <CarGarage />,
  } as const,
  {
    key: CreateClaimRequestCollisionScenario.HitSomeoneElsesProperty,
    value: CreateClaimRequestCollisionScenario.HitSomeoneElsesProperty,
    label: "Someone else's property",
    sublabel: "e.g. power pole, bollard, bus stop, wall, pillar",
    icon: <Bollard />,
  } as const,
  {
    key: CreateClaimRequestCollisionScenario.HitKangarooOrWildlife,
    value: CreateClaimRequestCollisionScenario.HitKangarooOrWildlife,
    label: "Kangaroo or wildlife",
    icon: <KangarooGRFX169 />,
  } as const,
  {
    key: CreateClaimRequestCollisionScenario.HitAnotherPersonsPetOrAnimal,
    value: CreateClaimRequestCollisionScenario.HitAnotherPersonsPetOrAnimal,
    label: "Another person's pet or animal",
    sublabel: "e.g. dog, cat, cow",
    icon: <PetGRFX206 />,
  } as const,
  {
    key: CreateClaimRequestCollisionScenario.SomethingElseHappened,
    value: CreateClaimRequestCollisionScenario.SomethingElseHappened,
    label: "Something else",
    icon: <Other />,
  } as const,
] as const satisfies CardChoiceOption[];
