import { FormQuestions } from "../../shared/types";
import { StartYourClaimFormValues } from "./types";

export const StartYourClaimQuestions = {
  date: {
    id: "date",
    name: "date",
    label: "Date of the incident",
  },
  time: {
    id: "time",
    name: "time",
    label: "Approximate time",
  },
} as const satisfies FormQuestions<StartYourClaimFormValues>;
