import { faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationCard } from "raci-react-library";

export const DamageImagesNotificationCard = () => (
  <NotificationCard
    id="damage-images-notification-card"
    icon={<FontAwesomeIcon icon={faImages} style={{ paddingTop: "4px" }} />}
    message={{
      title: "We'll let you know if we need images of the damage",
    }}
  />
);

export default DamageImagesNotificationCard;
