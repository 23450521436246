import {
  AboutYourCarRequestCarIsDriveable,
  AboutYourCarRequestCarWasTowed,
} from "raci-claims-motor-collision-clientproxy";
import { YesNoImNotSure } from "raci-react-library";
import { FormQuestions, YesNoImNotSureMap } from "../../shared/types";
import { AboutYourCarFormValues } from "./types";

export const aboutYourCarPageTitle = "About your car";

export const AboutYourCarQuestions = {
  damageDescription: {
    id: "damage-description",
    name: "damageDescription",
    label: "Please describe the damage to your car",
    subLabel: "What parts were damaged and how badly, e.g. right tail-light cracked.",
  },
  carWasTowed: {
    id: "car-was-towed",
    name: "carWasTowed",
    label: "Was your car towed?",
  },
  carIsDriveable: {
    id: "car-is-driveable",
    name: "carIsDriveable",
    label: "Can your car be safely driven to the repairer?",
  },
  preferredSuburbForRepairs: {
    id: "preferred-suburb-for-repairs",
    name: "preferredSuburbForRepairs",
    label: "Preferred area for repairs",
    subLabel: "WA only",
  },
  findRepairerOutsideWA: {
    id: "find-repairer-outside-wa",
    name: "findRepairerOutsideWA",
    label: "I'd like a repairer outside WA.",
  },
} as const satisfies FormQuestions<AboutYourCarFormValues>;

export const CarWasTowedYesNoImNotSure = {
  [YesNoImNotSure.Yes]: AboutYourCarRequestCarWasTowed.Yes,
  [YesNoImNotSure.No]: AboutYourCarRequestCarWasTowed.No,
  [YesNoImNotSure.ImNotSure]: AboutYourCarRequestCarWasTowed.ImNotSure,
} as const satisfies YesNoImNotSureMap<AboutYourCarRequestCarWasTowed>;

export const CarIsDriveableYesNoImNotSure = {
  [YesNoImNotSure.Yes]: AboutYourCarRequestCarIsDriveable.Yes,
  [YesNoImNotSure.No]: AboutYourCarRequestCarIsDriveable.No,
  [YesNoImNotSure.ImNotSure]: AboutYourCarRequestCarIsDriveable.ImNotSure,
} as const satisfies YesNoImNotSureMap<AboutYourCarRequestCarIsDriveable>;
