import { PolicyDetails } from "raci-claims-motor-collision-clientproxy";
import { SessionState } from "raci-react-library";

export enum RedirectType {
  Login = "login",
  Next = "next",
  Register = "register",
}

export interface BeforeYouStartFormProps {
  isLoggedIn: boolean;
  disabled: boolean;
  privacyUrl: string;
  handleRedirect: (redirectType: RedirectType) => Promise<void>;
}

export type BeforeYouStartState = SessionState<{
  policies: PolicyDetails[];
}>;
