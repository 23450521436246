import { ContactDetailsDisplay } from "raci-react-library";
import { ContactDetailsFormProps } from "./types";

export const ContactDetailsForm = ({
  isLoading,
  firstName,
  phoneType,
  maskedPhoneNumber,
  maskedEmail,
  onSubmit,
}: ContactDetailsFormProps) => {
  return isLoading ? null : (
    <ContactDetailsDisplay
      firstName={firstName ?? ""}
      phoneType={phoneType}
      maskedPhoneNumber={maskedPhoneNumber ?? ""}
      maskedEmail={maskedEmail ?? ""}
      onConfirmedCorrect={() => {
        onSubmit({ contactNumber: maskedPhoneNumber, email: maskedEmail });
      }}
      updateContactDetailsUrl={
        // Note: The redirect URL that is sent to MyRAC cannot have protocol in it, due to Sitecore Login not being able to handle it
        process.env.REACT_APP_MY_RAC_EDIT_CONTACT_DETAILS_PAGE
          ? `${process.env.REACT_APP_MY_RAC_EDIT_CONTACT_DETAILS_PAGE}?return_url=${window.location.href.replace("https://", "")}`
          : ""
      }
    />
  );
};

export default ContactDetailsForm;
