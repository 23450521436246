import { YesNo, useGetSessionState } from "raci-react-library";
import { OtherVehiclesInvolved } from "../../../views/AboutTheAccident/constants";
import { AboutTheAccidentState } from "../../../views/AboutTheAccident/types";
import { StartYourClaimState } from "../../../views/StartYourClaim/types";
import { FormRoute } from "../../routing/routes.config";

export type ClaimState = {
  isSingleVehicleCollisionClaim: boolean;
  isLiabilityOnlyClaim: boolean;
};

/**
 * @returns whether or not the current session is for a single vehicle collision claim, and whether it is a liability only claim or not
 * @throws when called before the AboutTheAccident page is completed
 */
export const useClaimState = (): ClaimState => {
  const { hasComprehensiveCover } = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const {
    otherVehiclesInvolved,
    claimForDamageToOwnCar,
    isCompleted: aboutTheAccidentIsCompleted,
  } = useGetSessionState<AboutTheAccidentState>(FormRoute.AboutTheAccident);

  if (!aboutTheAccidentIsCompleted) {
    throw new Error("useClaimState can not be called before AboutTheAccident is completed");
  }

  if (!hasComprehensiveCover) {
    return { isSingleVehicleCollisionClaim: false, isLiabilityOnlyClaim: true };
  }

  return {
    isSingleVehicleCollisionClaim:
      otherVehiclesInvolved === OtherVehiclesInvolved.None || otherVehiclesInvolved === OtherVehiclesInvolved.ImNotSure,
    isLiabilityOnlyClaim: claimForDamageToOwnCar === YesNo.No,
  };
};

export default useClaimState;
