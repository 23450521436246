import { Box, Grid, styled, Typography } from "@mui/material";
import { colors } from "@racwa/styles";
import { NotificationCard } from "raci-react-library";

export const StyledSummaryContainer = styled(Box)({
  marginLeft: "0px",
  marginRight: "0px",
  marginTop: "0px",
  marginBottom: "24px",
  backgroundColor: "white",
  padding: "16px",
  paddingTop: "16px",
  border: "1px solid",
  borderRadius: "3px",
  borderColor: colors.dieselLight,
});

export const StyledSummaryContainerHeader = styled(Typography)({
  paddingTop: "16px",
  paddingBottom: "16px",
});

export const StyledFirstGridItem = styled(Grid)({
  paddingTop: "8px",
  paddingBottom: "8px",
});

export const StyledGridItem = styled(Grid)({
  paddingTop: "16px",
  paddingBottom: "16px",
});

export const StyledNotificationCard = styled(NotificationCard)({
  marginBottom: "16px",
});
