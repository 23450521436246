import { OpenBackdrop } from "raci-react-library";
import React, { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { SILENT_LOGIN_REDIRECT_URL } from "../shared/constants";
import useSessionExpiry from "../shared/hooks/useSessionExpiry";
import useStandardPage from "../shared/hooks/useStandardPage";
import useStepperPage from "../shared/hooks/useStepperPage";
import { RouteGuard } from "../shared/routing/RouteGuard";
import { TitleGuard } from "../shared/routing/TitleGuard";
import { PreFormRoute, errorRoutes, getRouteUrl, preFormRoutes } from "../shared/routing/routes.config";
import useRoutes from "../shared/routing/useRoutes";

const RacwaStepperPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStepperTemplate })),
);
const RacwaStandardPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStandardPageTemplate })),
);

export const Views = () => {
  useSessionExpiry();
  const { formRoutes } = useRoutes();
  const stepperPageProps = useStepperPage();
  const standardPageProps = useStandardPage();

  return (
    <>
      <TitleGuard />
      <RouteGuard />
      <Routes>
        {/* Render a blank page to avoid iframe timeout issues, see:  https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2263*/}
        <Route path={SILENT_LOGIN_REDIRECT_URL} element={<></>} />

        <Route
          path={process.env.PUBLIC_URL}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStandardPageTemplate {...standardPageProps}>
                <Outlet />
              </RacwaStandardPageTemplate>
            </Suspense>
          }
        >
          {preFormRoutes.map(({ key, element }) => (
            <Route key={key} path={key} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>

        <Route
          path={process.env.PUBLIC_URL}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStepperPageTemplate {...stepperPageProps}>
                <Outlet />
              </RacwaStepperPageTemplate>
            </Suspense>
          }
        >
          <Route index element={<Navigate to={getRouteUrl(PreFormRoute.BeforeYouStart)} replace />} />
          {formRoutes.map(({ key, path, element }) => (
            <Route key={key} path={path} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>

        <Route
          path={process.env.PUBLIC_URL}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStandardPageTemplate {...standardPageProps}>
                <Outlet />
              </RacwaStandardPageTemplate>
            </Suspense>
          }
        >
          {errorRoutes.map(({ key, element }) => (
            <Route key={key} path={key} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>

        <Route path="*" element={<Navigate to={getRouteUrl(PreFormRoute.BeforeYouStart)} replace />} />
        <Route path="/" element={<Navigate to={getRouteUrl(PreFormRoute.BeforeYouStart)} replace />} />
      </Routes>
    </>
  );
};

export default Views;
