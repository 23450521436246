import { WhereAndHowRequestWerePoliceInvolved } from "raci-claims-motor-collision-clientproxy";
import { YesNoImNotSure } from "raci-react-library";
import { FormQuestions, YesNoImNotSureMap } from "../../shared/types";
import { WhereAndHowFormValues } from "./types";

export const whereAndHowItHappenedPageTitle = "Where and how it happened";

export const WhereAndHowQuestions = {
  provideTheLocation: {
    id: "provide-the-location",
    name: "provideTheLocation",
    label: "Where the incident happened",
  },
  whereTheIncidentHappenedMap: {
    id: "map-location",
    name: "whereTheIncidentHappenedMap",
    label: "Search maps",
  },
  whereTheIncidentHappenedDescription: {
    id: "description-location",
    name: "whereTheIncidentHappenedDescription",
    label: "Provide an address or location",
  },
  howTheIncidentHappened: {
    id: "how-the-incident-happened",
    name: "howTheIncidentHappened",
    label: "Please describe what happened",
  },
  werePoliceInvolved: {
    id: "were-police-involved",
    name: "werePoliceInvolved",
    label: "Were the police involved?",
  },
  policeReportNumber: {
    id: "police-report-number",
    name: "policeReportNumber",
    label: "Police report number",
  },
} as const satisfies FormQuestions<WhereAndHowFormValues>;

// TODO: generate client proxy with single YesNoImNotSure enum
// when this azure-functions-openapi-extension fix is released
// https://github.com/Azure/azure-functions-openapi-extension/issues/153
export const WerePoliceInvolvedYesNoImNotSure = {
  [YesNoImNotSure.Yes]: WhereAndHowRequestWerePoliceInvolved.Yes,
  [YesNoImNotSure.No]: WhereAndHowRequestWerePoliceInvolved.No,
  [YesNoImNotSure.ImNotSure]: WhereAndHowRequestWerePoliceInvolved.ImNotSure,
} as const satisfies YesNoImNotSureMap<WhereAndHowRequestWerePoliceInvolved>;
