import { gtm, virtualPageView } from "@racwa/analytics";
import { SessionTimeoutContent, useClearSession, useGetSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useFlowState from "../../shared/components/FlowStateProvider/useFlowState";
import { ErrorRoute, FormRoute, PreFormRoute, getRouteUrl } from "../../shared/routing/routes.config";
import { MoreAboutTheAccidentState } from "../MoreAboutTheAccident/types";

export const SessionTimeout = () => {
  const location = useLocation();
  const clearSession = useClearSession();
  const [, setFlowCompleted] = useFlowState();
  const { claimNumber } = useGetSessionState<MoreAboutTheAccidentState>(FormRoute.MoreAboutTheAccident);

  useEffect(() => {
    setFlowCompleted(ErrorRoute.SessionTimeout);

    gtm(
      virtualPageView({
        title: `Session timeout - previous page:  ${location.state?.referrer ?? document.referrer}`,
        url: location.pathname.toLocaleLowerCase(),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SessionTimeoutContent
      variant="claims"
      claimNumber={claimNumber}
      startClaimAgainUrl={getRouteUrl(PreFormRoute.BeforeYouStart)}
      onStartClaimAgain={clearSession}
    />
  );
};

export default SessionTimeout;
