import { event, gtm, virtualPageView } from "@racwa/analytics";
import { ClaimsMotorCollisionApiException, RepairerDetails } from "raci-claims-motor-collision-clientproxy";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import useFlowState from "../../../../shared/components/FlowStateProvider/useFlowState";
import { TrackPageStatus } from "../../../../shared/components/TrackPageChanges/types";
import { BackdropMessage, STORAGE_KEY_TRACK_PAGE_VIEWS } from "../../../../shared/constants";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { ErrorRoute, FormRoute, allRoutes } from "../../../../shared/routing/routes.config";
import useDocumentTitle from "../../../../shared/routing/useDocumentTitle";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { ConfirmationState } from "../../../Confirmation/types";
import { ReviewYourClaimState } from "../../../ReviewYourClaim/types";
import { RepairerOptionsFormProps, RepairerOptionsFormValues, RepairerOptionsState } from "../../types";

export const useRepairerOptions = (): RepairerOptionsFormProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const location = useLocation();
  const documentTitle = useDocumentTitle(allRoutes);
  const [{ isFlowCompleted }] = useFlowState();
  const hasHireCarCover = !!useGetSessionState<ReviewYourClaimState>(FormRoute.ReviewYourClaim).hasHireCarCover;
  const [, setConfirmationState] = useSessionState<ConfirmationState>({ specificKey: FormRoute.Confirmation });
  const [repairerOptionsState, setRepairerOptionsState] = useSessionState<RepairerOptionsState>();
  const repairers = repairerOptionsState.repairers ?? [];
  const [trackPageViewState] = useState<TrackPageStatus>(() => {
    const trackPageState = sessionStorage.getItem(STORAGE_KEY_TRACK_PAGE_VIEWS);
    return trackPageState
      ? JSON.parse(trackPageState)
      : ({ reviewYourClaim: false, repairerOptions: true } satisfies TrackPageStatus);
  });

  const form = useForm<RepairerOptionsFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      selectedRepairer: repairerOptionsState.selectedRepairer ?? repairers[0]?.id,
    },
  });

  const triggerRepairerOptionGtmEvent = (selectedRepairer: RepairerDetails | undefined) => {
    if (!selectedRepairer) {
      gtm(event("Quote from own repairer selected"));
      return;
    }

    if (repairers.length > 0 && repairers.length <= 2) {
      const repairerIndex = repairers.findIndex((repairer) => repairer.id === selectedRepairer.id);
      repairerIndex >= 0 && gtm(event(`Repairer ${repairerIndex + 1} of ${repairers.length} selected`));
    }
  };

  const onSubmit: RepairerOptionsFormProps["onSubmit"] = async ({ selectedRepairer }) => {
    const repairer = repairers?.find((repairer) => repairer.id === selectedRepairer);

    try {
      setBackdrop(true, BackdropMessage.ProcessingYourClaim);

      !repairerOptionsState.isSubmitted && triggerRepairerOptionGtmEvent(repairer);

      setRepairerOptionsState({
        repairers,
        selectedRepairer,
        isSubmitted: true,
        isCompleted: false,
      });

      const { lodgementDetails } = (await apiClient.updateClaimRepairer({ repairerId: repairer?.id })).result;

      setRepairerOptionsState({
        repairers,
        selectedRepairer,
        isSubmitted: true,
        isCompleted: true,
      });
      setConfirmationState({
        lodgementDetails,
        selectedRepairer: repairer ?? "Get a quote",
      });

      navigate(FormRoute.Confirmation);
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /claims/repairer`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false, BackdropMessage.ProcessingYourClaim);
    }
  };

  useEffect(() => {
    const { selectedRepairer, isSubmitted } = repairerOptionsState;

    if (isSubmitted && !isFlowCompleted) {
      selectedRepairer ? onSubmit({ selectedRepairer }) : navigate(ErrorRoute.SystemUnavailable);
    }

    if (trackPageViewState.repairerOptions) {
      gtm(virtualPageView({ url: location.pathname.toLocaleLowerCase(), title: documentTitle }));
      sessionStorage.setItem(
        STORAGE_KEY_TRACK_PAGE_VIEWS,
        JSON.stringify({ ...trackPageViewState, repairerOptions: false }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    form,
    repairers,
    hasHireCarCover,
    onSubmit,
  };
};

export default useRepairerOptions;
