import {
  AboutTheAccidentQuestions,
  singleVehicleCollisionScenarioOptions,
} from "../../../../../AboutTheAccident/constants";
import { AboutTheAccidentFormValues, AboutTheAccidentState } from "../../../../../AboutTheAccident/types";
import { FormSummary } from "../../../../types";

type Args = Pick<AboutTheAccidentState, keyof AboutTheAccidentFormValues>;

export const summariseAboutTheAccident = ({
  otherVehiclesInvolved,
  singleVehicleCollisionScenario,
  propertyDamaged,
  claimForDamageToOwnCar,
}: Args) =>
  ({
    otherVehiclesInvolved: {
      question: AboutTheAccidentQuestions.otherVehiclesInvolved.label,
      answer: otherVehiclesInvolved,
    },
    singleVehicleCollisionScenario: singleVehicleCollisionScenario
      ? {
          question: AboutTheAccidentQuestions.singleVehicleCollisionScenario.label,
          answer: singleVehicleCollisionScenarioOptions.find((opt) => opt.value === singleVehicleCollisionScenario)
            ?.label,
        }
      : undefined,
    propertyDamaged: propertyDamaged
      ? {
          question: AboutTheAccidentQuestions.propertyDamaged.label({ singleVehicleCollisionScenario }),
          answer: propertyDamaged,
        }
      : undefined,
    claimForDamageToOwnCar: claimForDamageToOwnCar
      ? {
          question: AboutTheAccidentQuestions.claimForDamageToOwnCar.label,
          answer: claimForDamageToOwnCar,
        }
      : undefined,
  }) as const satisfies FormSummary<AboutTheAccidentFormValues>;

export default summariseAboutTheAccident;
