import {
  CreateClaimRequestClaimForDamageToOwnCar,
  CreateClaimRequestDirectionOfTravel,
  CreateClaimRequestOtherVehiclesInvolved,
} from "raci-claims-motor-collision-clientproxy";
import { RadioButtonGroupItem, YesNo } from "raci-react-library";
import { FormQuestions } from "../../shared/types";
import { OtherVehiclesInvolved } from "../AboutTheAccident/constants";
import { ForwardScenario } from "./scenarios";
import { MoreAboutTheAccidentFormValues } from "./types";

export const moreAboutTheAccidentPageTitle = "More about the accident";

export const MoreAboutTheAccidentQuestions = {
  directionOfTravel: {
    id: "direction-of-travel",
    name: "directionOfTravel",
    label: "Your car was...",
  },
  forwardScenario: {
    id: "forward-scenario",
    name: "forwardScenario",
    label: "How it happened",
  },
  multiVehicleCollisionScenario: {
    id: "multi-vehicle-collision-scenario",
    name: "multiVehicleCollisionScenario",
    label: ({
      directionOfTravel,
      forwardScenario,
    }: {
      directionOfTravel?: CreateClaimRequestDirectionOfTravel;
      forwardScenario?: ForwardScenario;
    }) => {
      if (forwardScenario) {
        switch (forwardScenario) {
          case ForwardScenario.AVehicleHitYourCar:
            return "Did the other vehicle...";
          case ForwardScenario.YourCarHitAnotherVehicle:
            return "Did your car...";
          case ForwardScenario.OurVehiclesHitOneAnother:
            return "The vehicles...";
          default:
            return "";
        }
      }

      switch (directionOfTravel) {
        case CreateClaimRequestDirectionOfTravel.Reversing:
          return "Did your car...";
        case CreateClaimRequestDirectionOfTravel.Parked:
          return "Did another vehicle hit your car?";
        case CreateClaimRequestDirectionOfTravel.Stationary:
          return "What scenario best describes what happened?";
        default:
          return "";
      }
    },
  },
} as const satisfies FormQuestions<MoreAboutTheAccidentFormValues>;

export const directionOfTravelOptions = [
  {
    id: "0",
    value: CreateClaimRequestDirectionOfTravel.Forward,
    label: "Driving forward",
  } as const,
  {
    id: "1",
    value: CreateClaimRequestDirectionOfTravel.Reversing,
    label: "Reversing",
  } as const,
  {
    id: "2",
    value: CreateClaimRequestDirectionOfTravel.Parked,
    label: "Parked",
    sublabel: "Car engine is off and handbrake is on",
  } as const,
  {
    id: "3",
    value: CreateClaimRequestDirectionOfTravel.Stationary,
    label: "Stationary",
    sublabel: "Car is not moving, e.g. stopped in traffic",
  } as const,
] as const satisfies RadioButtonGroupItem[];

export const OtherVehiclesInvolvedMap = {
  [OtherVehiclesInvolved.ImNotSure]: CreateClaimRequestOtherVehiclesInvolved.Unsure,
  [OtherVehiclesInvolved.None]: CreateClaimRequestOtherVehiclesInvolved.None,
  [OtherVehiclesInvolved.One]: CreateClaimRequestOtherVehiclesInvolved.One,
} as const satisfies Record<
  Extract<
    OtherVehiclesInvolved,
    OtherVehiclesInvolved.ImNotSure | OtherVehiclesInvolved.None | OtherVehiclesInvolved.One
  >,
  CreateClaimRequestOtherVehiclesInvolved
>;

// TODO: generate client proxy with single YesNoUnknown enum
// when this azure-functions-openapi-extension fix is released
// https://github.com/Azure/azure-functions-openapi-extension/issues/153
export const ClaimForDamageToOwnCarYesNoMap = {
  [YesNo.Yes]: CreateClaimRequestClaimForDamageToOwnCar.Yes,
  [YesNo.No]: CreateClaimRequestClaimForDamageToOwnCar.No,
} as const satisfies Record<YesNo, CreateClaimRequestClaimForDamageToOwnCar>;
