import { Box, Button, Grid } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { CreateClaimRequestDirectionOfTravel } from "raci-claims-motor-collision-clientproxy";
import { RadioButtonGroup } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import ClaimDetailsCard from "../../shared/components/ClaimDetailsCard";
import PageTitle from "../../shared/components/PageTitle";
import { MoreAboutTheAccidentQuestions, directionOfTravelOptions, moreAboutTheAccidentPageTitle } from "./constants";
import { getForwardScenarios, getMultiVehicleCollisionScenarioRadioGroupItems } from "./scenarios";
import { MoreAboutTheAccidentFormProps } from "./types";

const { directionOfTravel, forwardScenario, multiVehicleCollisionScenario } = MoreAboutTheAccidentQuestions;

export const MoreAboutTheAccidentForm = ({
  form,
  claimState: { isSingleVehicleCollisionClaim, isLiabilityOnlyClaim },
  claimCreated,
  onSubmit,
}: MoreAboutTheAccidentFormProps) => {
  const {
    handleSubmit,
    control,
    unregister,
    formState: { isSubmitting },
  } = form;

  const directionOfTravelValue = useWatch({ control, name: "directionOfTravel" });
  const forwardScenarioValue = useWatch({ control, name: "forwardScenario" });

  const showForwardScenario =
    !isSingleVehicleCollisionClaim && directionOfTravelValue === CreateClaimRequestDirectionOfTravel.Forward;

  const multiVehicleCollisionScenarioOptions =
    !isSingleVehicleCollisionClaim && directionOfTravelValue
      ? getMultiVehicleCollisionScenarioRadioGroupItems(
          isLiabilityOnlyClaim,
          directionOfTravelValue,
          forwardScenarioValue,
        )
      : [];

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle title={moreAboutTheAccidentPageTitle} id="header" />
          </Grid>

          <Grid item xs={12}>
            <RadioButtonGroup
              id={directionOfTravel.id}
              name={directionOfTravel.name}
              label={directionOfTravel.label}
              items={directionOfTravelOptions}
              disabled={claimCreated}
              onChange={(_, value: string) => {
                unregister("forwardScenario");
                unregister("multiVehicleCollisionScenario");
                gtm(fieldTouched("Your car was"));
                gtm(
                  event(`Your car was - ${directionOfTravelOptions.find((option) => option.value === value)?.label}`),
                );
              }}
              fullwidth
            />
          </Grid>

          {showForwardScenario && (
            <Grid item xs={12}>
              <RadioButtonGroup
                id={forwardScenario.id}
                name={forwardScenario.name}
                label={forwardScenario.label}
                items={getForwardScenarios(isLiabilityOnlyClaim)}
                disabled={claimCreated}
                onChange={(_, v) => {
                  unregister("multiVehicleCollisionScenario");
                  gtm(fieldTouched("How it happened"));
                  gtm(event(`How it happened - ${v}`));
                }}
                fullwidth
              />
            </Grid>
          )}

          {multiVehicleCollisionScenarioOptions.length > 0 && (
            <Grid item xs={12}>
              <RadioButtonGroup
                key={`${directionOfTravelValue}${forwardScenarioValue ? `-${forwardScenarioValue}` : ""}`}
                id={multiVehicleCollisionScenario.id}
                name={multiVehicleCollisionScenario.name}
                label={multiVehicleCollisionScenario.label({
                  directionOfTravel: directionOfTravelValue,
                  forwardScenario: forwardScenarioValue,
                })}
                items={multiVehicleCollisionScenarioOptions}
                disabled={claimCreated}
                onChange={(_, v) => {
                  const label = multiVehicleCollisionScenario.label({
                    directionOfTravel: directionOfTravelValue,
                    forwardScenario: forwardScenarioValue,
                  });
                  const trimmedLabel = label.replaceAll(".", "");
                  gtm(fieldTouched(trimmedLabel));
                  gtm(
                    event(
                      `${trimmedLabel} - ${multiVehicleCollisionScenarioOptions.find((item) => item.value === v)?.label}`,
                    ),
                  );
                }}
                fullwidth
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box sx={{ paddingTop: "8px", paddingBottom: "16px" }}>
              <ClaimDetailsCard claimCreated={claimCreated} />
            </Box>
            <Button
              type="submit"
              id="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ marginTop: claimCreated ? "36px" : "28px", marginBottom: "16px" }}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
