import { YesNo, YesNoUnknown } from "raci-react-library";
import { ClaimState } from "../../../../../../shared/hooks/useClaimState";
import { MoreThirdPartyDetailsQuestions } from "../../../../../MoreThirdPartyDetails/constants";
import {
  MoreThirdPartyDetailsFormValues,
  MoreThirdPartyDetailsState,
} from "../../../../../MoreThirdPartyDetails/types";
import { ThirdPartyDetailsState } from "../../../../../ThirdPartyDetails/types";
import { FormSummary } from "../../../../types";

type Args = Pick<ClaimState, "isSingleVehicleCollisionClaim"> &
  Pick<ThirdPartyDetailsState, "hasOwnerDetails"> &
  Pick<MoreThirdPartyDetailsState, keyof MoreThirdPartyDetailsFormValues>;

export const summariseMoreThirdPartyDetails = ({
  isSingleVehicleCollisionClaim,
  hasOwnerDetails,
  isDriverTheOwner,
  ownersNameAndContactDetails,
  ownersInsuranceCompany,
  ownersClaimNumber,
  damageDescription,
}: Args) => {
  if (hasOwnerDetails !== YesNo.Yes) {
    return undefined;
  }

  return {
    isDriverTheOwner: isSingleVehicleCollisionClaim
      ? undefined
      : {
          question: MoreThirdPartyDetailsQuestions.isDriverTheOwner.label,
          answer: isDriverTheOwner,
        },
    ownersNameAndContactDetails:
      isDriverTheOwner === YesNoUnknown.No
        ? {
            question: MoreThirdPartyDetailsQuestions.ownersNameAndContactDetails.label,
            answer: ownersNameAndContactDetails || "-",
          }
        : undefined,
    ownersInsuranceCompany: {
      question: MoreThirdPartyDetailsQuestions.ownersInsuranceCompany.label({ isSingleVehicleCollisionClaim }),
      answer: ownersInsuranceCompany?.description || "-",
    },
    ownersClaimNumber: {
      question: MoreThirdPartyDetailsQuestions.ownersClaimNumber.label,
      answer: ownersClaimNumber || "-",
    },
    damageDescription: {
      question: MoreThirdPartyDetailsQuestions.damageDescription.label({ isSingleVehicleCollisionClaim }),
      answer: damageDescription || "-",
    },
  } as const satisfies FormSummary<MoreThirdPartyDetailsFormValues>;
};

export default summariseMoreThirdPartyDetails;
