import { Marker, defaultWACoordinates } from "..";

export const useRacwaMaps = (
  setLocation: React.Dispatch<any>,
  map?: google.maps.Map,
  onLocationSelected?: (location: Marker) => void,
) => {
  const fireLocation = async (coordinate: google.maps.LatLng) => {
    if (onLocationSelected) {
      onLocationSelected({
        latitude: coordinate.lat(),
        longitude: coordinate.lng(),
        zoom: map?.getZoom(),
      });
    }
  };

  const addMapEventListener = (ev: "dragend" | "zoom_changed") => {
    if (map) {
      return google.maps.event.addListener(map, ev, async () => {
        await updateCenter();
      });
    }
  };

  const removeMapEventListener = (listener: google.maps.MapsEventListener | undefined) => {
    if (listener) {
      google.maps.event.removeListener(listener);
    }
  };

  const updateCenter = async () => {
    const center = map?.getCenter();
    if (center !== undefined) {
      setLocation({ lat: center.lat(), lng: center.lng() });
      await fireLocation(center);
    }
  };

  const membersLocation = (isMounted: boolean) => {
    if (isMounted) {
      navigator?.geolocation?.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          if (latitude && longitude && map) {
            map?.setCenter(userLocation);
            map?.setZoom(19);
            fireLocation(new google.maps.LatLng(latitude, longitude));
          }
        },
        () => {
          //getCurrentPosition error call back, most likely user denied location access
          map?.setCenter(defaultWACoordinates);
          map?.setZoom(6);
          fireLocation(new google.maps.LatLng(defaultWACoordinates));
        },
      );
    }
  };

  const panToPlace = (place: google.maps.places.PlaceResult) => {
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    if (lat !== undefined && lng !== undefined) {
      map?.panTo({ lat, lng });
      map?.setZoom(19);
      fireLocation(new google.maps.LatLng(lat, lng));
    }
  };

  return {
    addMapEventListener,
    removeMapEventListener,
    panToPlace,
    updateCenter,
    membersLocation,
  };
};

export default useRacwaMaps;
