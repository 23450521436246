import { faCar } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { CardWithIcon } from "raci-react-library";

export const HireCarCoverCard = () => (
  <CardWithIcon
    icon={faCar}
    iconSize={"24px"}
    iconStyle={{ marginTop: "5px" }}
    title={<Typography variant="h3">You've got hire car cover</Typography>}
    paragraphs={[<Typography variant="body1">When you need a hire car, call us.</Typography>]}
    spacing={3}
    data-testid="hire-car-cover-card"
  />
);

export default HireCarCoverCard;
